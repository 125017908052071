/* eslint-disable react/function-component-definition */

import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ICustomAlert } from "./types";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CustomAlert: React.FC<ICustomAlert> = ({ sx, text = "Alert example", severity = "success", onClose, open = false }) => {
	return (
		<Snackbar open={open} autoHideDuration={2000} onClose={onClose}>
			<Alert onClose={onClose} severity={severity} sx={sx}>
				{text}
			</Alert>
		</Snackbar>
	);
};

export default CustomAlert;
