import { CssBaseline, ThemeProvider } from "@mui/material";
import { ProviderTree } from "@utils/index";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { LoadingContextProvider } from "./contexts/LoadingStateContext";
import "./index.css";
import { Navigation } from "./v2/routes/routes";
import theme from "./styles/Themes";
import { DynamicViewTagContextProvider } from "./v2/contexts";
import { SidebarProvider } from "./v2/contexts/SidebardContext";
import { TabsUpdateProvider } from "./v2/contexts/TabUpdateContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

const Providers = ProviderTree([
  [ThemeProvider, { theme }],
  [LoadingContextProvider],
  [AuthProvider],
  [DynamicViewTagContextProvider],
  [SidebarProvider],
  [TabsUpdateProvider],
]);

root.render(
  <StrictMode>
    <BrowserRouter>
      <Providers>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          <Navigation />
        </QueryClientProvider>
      </Providers>
    </BrowserRouter>
  </StrictMode>
);
