/* eslint-disable react/function-component-definition */
import { ChevronLeft, Menu } from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import { IconButton, Stack, Toolbar } from "@mui/material";
import { useState } from "react";
import peopleLogo from "src/assets/logo_white.png";
import PopoverComponent from "src/v2/components/Popover/Popover";
import { IconButton as IconButtonv2 } from "src/v2/components/atoms/IconButton";
import { BackDrop, CustomAlert, SearchBar } from "src/v2/components/molecules";
import { NotificationContainer } from "src/v2/components/molecules/NotificationContainer/NotificationContainer";
import { TimeNotification } from "src/v2/components/molecules/TimeNotification/TimeNotification";
import { useDynamicViewTagContext } from "src/v2/hooks";
import { SyncAllData } from "src/v2/services/peopleData.service";
import { NotificationBadge } from "../../atoms/NotificationsBadge/NotificationsBadge";
import { UserInformationPopover } from "../UserInformationPopover";
import {
  LogoAndButtonsContainer,
  NavBarContainer,
  NavBarInnerContainer,
  NavBarLogoContainer,
  bellStyles,
} from "./NavBarStyleComponents";
import { INavBarProps } from "./types";

const NavBar: React.FC<INavBarProps> = ({
  open = true,
  setOpen = () => {},
}) => {
  const handleDrawer = () => {
    setOpen(!open);
  };
  const { bellIcon } = bellStyles;
  const [openPopover, setOpenPopover] = useState(false);
  const [isNotificationChecked, setIsNotificationChecked] = useState(false);
  const { TimeNotificationData } = useDynamicViewTagContext();
  const [openBackDrop, setOpenBackDrop] = useState(false);

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleTimeNotificationCheckboxChange = () => {
    setIsNotificationChecked(!isNotificationChecked);
  };
  const notifications = [
    <TimeNotification
      key='TimeNotification'
      checked={isNotificationChecked}
      handleTimeNotificationCheckboxChange={
        handleTimeNotificationCheckboxChange
      }
    />,
  ];
  const [syncComplete, setSyncComplete] = useState(false);
  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Data synchronized correctly",
  });

  const syncData = async () => {
    setOpenBackDrop(true);
    if (await SyncAllData()) {
      return true;
    }
    return false;
  };
  const handleClick = async () => {
    const response = await syncData();
    setOpenBackDrop(false);
    if (await response) {
      setAlertInformation({
        severity: "success",
        message: "Data synchronized correctly",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setAlertInformation({
        severity: "error",
        message: "Error synchronizing data",
      });
    }
    setSyncComplete(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSyncComplete(false);
  };

  return (
    <>
      <NavBarContainer data-testid='nav-bar-container'>
        <NavBarInnerContainer>
          <LogoAndButtonsContainer>
            <Toolbar variant='regular'>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                edge='start'
                onClick={handleDrawer}
                sx={{
                  ...(open && { display: "none" }),
                }}
              >
                <Menu fontSize='large' sx={{ color: "white" }} />
              </IconButton>
              {open && (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawer}
                >
                  <ChevronLeft fontSize='large' sx={{ color: "white" }} />
                </IconButton>
              )}
            </Toolbar>
            <NavBarLogoContainer>
              <a href='/'>
                <img src={peopleLogo} alt='Logo' className='max-h-16' />
              </a>
            </NavBarLogoContainer>
          </LogoAndButtonsContainer>

          <Stack direction='row' spacing={2} alignItems='center'>
            <PopoverComponent
              isOpen={openPopover}
              mainComponent={
                <IconButton sx={bellIcon}>
                  {!isNotificationChecked ? (
                    <NotificationBadge
                      number={TimeNotificationData?.values.notification_number}
                    />
                  ) : (
                    <NotificationBadge number={0} />
                  )}
                </IconButton>
              }
              positions={["bottom"]}
              withWidth={false}
              onClick={(newState) => setOpenPopover(newState)}
            >
              <NotificationContainer handleClose={handleClosePopover}>
                {notifications}
              </NotificationContainer>
            </PopoverComponent>
            <IconButtonv2
              style={{
                "color": "#fbe3d6",
                "background": "transparent",
                "boxShadow": "none",
                "padding": "1.5rem",
                "transition": "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "rotate(45deg)",
                  background: "transparent",
                },
                "&:active": {
                  boxShadow: "none",
                },
                "zIndex": "0",
              }}
              title='Sync data with external apps'
              tooltipPlacement='bottom'
              icon={<CachedIcon fontSize='large' />}
              onClick={handleClick}
            />
            <SearchBar />
            <CustomAlert
              sx={{ marginLeft: "50px" }}
              text={alertInformation.message}
              severity={
                alertInformation.severity as
                  | "error"
                  | "success"
                  | "info"
                  | "warning"
              }
              open={syncComplete}
              onClose={handleClose}
            />
            <UserInformationPopover />
          </Stack>
        </NavBarInnerContainer>
      </NavBarContainer>
      <BackDrop
        sx={{ zIndex: 10, width: "100%", height: "100%", margin: "0" }}
        openBackdrop={openBackDrop}
      />
    </>
  );
};

export default NavBar;
