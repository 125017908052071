/* eslint-disable react/function-component-definition */
import { Box } from "@mui/material";
import React, { useContext } from "react";
import { ILoadingContextState } from "src/contexts/IContexts";
import LoadingContext from "src/contexts/LoadingStateContext";
import { useListPeople } from "src/hooks/useListPeople";
import { joinNameLastName } from "../../../../utils";
import { Autocomplete } from "../../atoms";
import { SearchBarProps } from "./types";

export const SearchBar: React.FC<SearchBarProps> = () => {
  const { data: personRowData = [] } = useListPeople();
  const { loading } = useContext(LoadingContext) as ILoadingContextState;

  const handleClick = (ioetId: string) => {
    window.open(`/person/${ioetId}`, "_blank", "noopener,noreferrer");
  };

  return (
    <Autocomplete
      color='white'
      sx={{
        "width": 250,
        "& .MuiAutocomplete-inputRoot": {
          bgcolor: "white",
          borderRadius: "5px",
        },
        "&.Mui-focused .MuiAutocomplete-inputRoot, &:hover .MuiAutocomplete-inputRoot":
          {
            bgcolor: "white",
          },
        "& .MuiInputLabel-root.Mui-focused": {
          color: (theme) => theme.palette.grey[700],
        },
      }}
      loading={loading}
      options={personRowData}
      getOptionLabel={(option: any) =>
        joinNameLastName(option.name, option.lastName)
      }
      renderOption={(renderProps, option: any) => (
        <Box
          {...renderProps}
          component='li'
          key={option.ioetId}
          onClick={() => handleClick(option.ioetId)}
        >
          {joinNameLastName(option.name, option.lastName)}
        </Box>
      )}
      inputProps={{ label: "Search People", variant: "filled" }}
    />
  );
};
