import { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { ITextFieldProps } from "src/v2/components/atoms/TextField";
import { IReview } from "src/v2/models";
import { CurrentValues } from "src/v2/models/review.model";

export type ReviewFormProps = {
  reviewData?: IReview;
  handleAccordion?: () => void;
  setAlertInformation: Dispatch<
    SetStateAction<{
      severity: string;
      message: string;
    }>
  >;
  setSavedData: Dispatch<SetStateAction<boolean>>;
  currentValues?: CurrentValues;
  nextReviewDate?: Dayjs | null;
  disableGutters?: boolean;
  isPastReview?: boolean;
};

export const textFieldConfig: ITextFieldProps = {
  variant: "outlined",
  size: "small",
  fullWidth: true,
  hiddenLabel: true,
  read: false,
  onChange: () => {},
  sx: {
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      textAlign: "center",
    },
    "backgroundColor": "white",
  },
};

const ioetLevelOptions = [
  { label: "Mid level 1", value: "Mid level 1" },
  { label: "Junior 1", value: "Junior 1" },
  { label: "Senior", value: "Senior" },
  { label: "Mid level 2", value: "Mid level 2" },
  { label: "Junior 2", value: "Junior 2" },
  { label: "Administrative 1", value: "Administrative 1" },
  { label: "Tech lead", value: "Tech lead" },
  { label: "Intern", value: "Intern" },
];

export const SelectOptions = (isPastReview: boolean) => {
  const options = [
    { label: "Trainee Engineer", value: "Trainee Engineer" },
    { label: "Trainee Engineer PM/BA", value: "Trainee Engineer PM/BA" },
    { label: "Junior Software Engineer", value: "Junior Software Engineer" },
    { label: "Junior PM/BA", value: "Junior PM/BA" },
    { label: "PM/BA", value: "PM/BA" },
    { label: "Software Engineer", value: "Software Engineer" },
    { label: "DevOps Engineer", value: "DevOps Engineer" },
    { label: "QA Engineer", value: "QA Engineer" },
    { label: "Specialist Engineer", value: "Specialist Engineer" },
    { label: "Senior Software Engineer", value: "Senior Software Engineer" },
    { label: "Senior PM/BA", value: "Senior PM/BA" },
    { label: "Senior DevOps Engineer", value: "Senior DevOps Engineer" },
    { label: "Senior QA Engineer", value: "Senior QA Engineer" },
    {
      label: "Senior Specialist Engineer",
      value: "Senior Specialist Engineer",
    },
    { label: "Lead Software Engineer", value: "Lead Software Engineer" },
    { label: "Lead QA Engineer", value: "Lead QA Engineer" },
    { label: "Lead DevOps Engineer", value: "Lead DevOps Engineer" },
    { label: "Lead Specialist Engineer", value: "Lead Specialist Engineer" },
  ];
  return isPastReview ? options.concat(ioetLevelOptions) : options;
};

export const typographyStyles = { fontWeight: 900 };
