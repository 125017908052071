import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import { ILoadingButtonProps } from "./types";

function LoadingButtons({
  loading,
  loadingPosition,
  variant,
  text,
  color,
  sx,
  onClick,
  startIcon,
}: ILoadingButtonProps) {
  return (
    <LoadingButton
      loading={loading}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      variant={variant}
      onClick={onClick}
      sx={{ textTransform: "capitalize", ...sx }}
      color={color}
    >
      {text}
    </LoadingButton>
  );
}
export default LoadingButtons;
