// http client --------------------------------------------------------------------------------

import dayjs from "dayjs";

interface DetailErrorResponse {
  loc: string[];
  msg: string;
  type: string;
}

interface StatusMessageFunction<T> {
  (error: T): string;
}

interface StatusMessages {
  [statusCode: number | string]: StatusMessage;
}

type StatusMessage = string | StatusMessageFunction<any>;

export const statusMessages: StatusMessages = {
  422: (error: { response: { data: { detail: DetailErrorResponse[] } } }) => {
    const details = error.response.data.detail;
    const message = details
      .map(
        (detail: DetailErrorResponse) =>
          `${detail.loc.join("-")}: ${detail.msg}`
      )
      .join(", ");
    return message;
  },
  401: "You are not authorized to access to the server resources",
  409: (error: { response: { data: { message: string } } }) =>
    error.response.data.message,
};
// ---------------------------------------------------------------------------------------------

export type DynamicModel = {
  values: Object;
};

type ViewTag =
  | "simple_string"
  | "checkbox"
  | "email"
  | "date"
  | "array"
  | "object_modal"
  | "details_button"
  | "ACTION"
  | "select"
  | "chip";

interface DataField {
  label: string;
  view_tag: ViewTag;
  frozen: boolean;
  nullable: boolean;
  width?: number;
}

export type IDataDefinitionSchema = {
  fields: {
    [fieldName: string]: DataField;
  };
};

export const dataDefinitionSchema: IDataDefinitionSchema = {
  fields: {
    name: {
      label: "name",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    lastName: {
      label: "lastName",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    isNewHire: {
      label: "isNewHire",
      view_tag: "checkbox",
      frozen: true,
      nullable: true,
    },
    ioetId: {
      label: "ioetId",
      view_tag: "simple_string",
      frozen: false,
      nullable: true,
    },
    ioetEmail: {
      label: "ioetEmail",
      view_tag: "email",
      frozen: false,
      nullable: true,
    },
    contractType: {
      label: "contractType",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    startDate: {
      label: "startDate",
      view_tag: "date",
      frozen: true,
      nullable: true,
    },
    endDate: {
      label: "endDate",
      view_tag: "date",
      frozen: true,
      nullable: true,
    },
    timeOnTheJob: {
      label: "timeOnTheJob",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    status: {
      label: "status",
      view_tag: "object_modal",
      frozen: false,
      nullable: true,
    },
    workTitles: {
      label: "workTitles",
      view_tag: "object_modal",
      frozen: true,
      nullable: true,
    },
    level: {
      label: "level",
      view_tag: "simple_string",
      frozen: false,
      nullable: true,
    },
    country: {
      label: "country",
      view_tag: "simple_string",
      frozen: false,
      nullable: true,
    },
    office: {
      label: "office",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    gender: {
      label: "gender",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    englishLevel: {
      label: "englishLevel",
      view_tag: "simple_string",
      frozen: true,
      nullable: true,
    },
    promisedReviewDate: {
      label: "promisedReviewDate",
      view_tag: "date",
      frozen: true,
      nullable: true,
    },
    compensation: {
      label: "compensation",
      view_tag: "object_modal",
      frozen: true,
      nullable: true,
    },
  },
} as const;

export interface INotesCategory {
  id: string;
  createdAt: dayjs.Dayjs;
  editedAt: dayjs.Dayjs;
  date: string;
  author: string;
  category: string;
  note: string;
  section: string;
}
export interface INotesClient {
  id: string;
  createdAt: dayjs.Dayjs;
  editedAt: dayjs.Dayjs;
  date: string;
  author: string;
  category: string;
  note: string;
  section: string;
}

export interface IReviewRecord {
  id: string;
  createdAt: dayjs.Dayjs;
  createdBy: string;
  editedAt: dayjs.Dayjs;
  reviewDate: string;
  comments?: string;
  results?: string;
  proposedSalary: number;
  proposedTitle: string;
  currentSalary: number;
  currentTitle: string;
  isDone: boolean;
}
