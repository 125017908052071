import { Box, Drawer, useMediaQuery, useTheme } from "@mui/material";
import { getTimeOnJob } from "src/utils/getTimeOnJob";
import { joinNameLastName } from "src/utils/stringJoins";
import { Avatar } from "src/v2";
import Typography from "src/v2/components/atoms/Typography/Typography";
import DataAvatar from "src/v2/components/molecules/DataAvatar/DataAvatar";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import IconGroup from "src/v2/components/molecules/IconGroup/IconGroup";
import TypographyGroup from "src/v2/components/molecules/TypographyGroup/TypographyGroup";
import {
  SNACKBAR_BACKGROUND_COLOR,
  SNACKBAR_COLOR,
} from "src/v2/constants/colors";
import { Dict } from "src/v2/models";

export interface ISideBarProfileProps {
  personInformation: Dict;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
}

export function SideBarProfile(props: ISideBarProfileProps) {
  const { personInformation: personData, setOpen, open } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const columnGap = "5%";

  const content = (
    <Box
      component='aside'
      data-testid='side-bar-profile-container'
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: 2,
        p: 5,
        height: "100%",
        width: { xs: 300, lg: "auto" },
        backgroundColor: SNACKBAR_COLOR,
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          justifyContent: "center",
          alignItems: "center",
          px: 2,
        }}
      >
        <Avatar
          alt={personData.name}
          width='100%'
          height='auto'
          srcImage='https://t4.ftcdn.net/jpg/03/31/69/91/360_F_331699188_lRpvqxO5QRtwOM05gR50ImaaJgBx68vi.jpg'
        />
      </Box>

      <Box
        data-testid='data-icon-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <DataIcon
          iconName={{ icon: "smartphone" }}
          text={{ children: personData.mobilePhone, variant: "body1" }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: "mail" }}
          text={{ children: personData.ioetEmail, variant: "body1" }}
          columnGap={columnGap}
        />
      </Box>

      <Box data-testid='social-media-container'>
        <IconGroup
          icons={[{ icon: "linkedin" }, { icon: "x" }, { icon: "facebook" }]}
        />
      </Box>

      <Box
        data-testid='hire-date-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <hr
          style={{
            backgroundColor: SNACKBAR_BACKGROUND_COLOR,
            height: 5,
            marginRight: "10%",
          }}
        />
        <Typography
          variant='body1'
          sx={{ fontWeight: "bold", color: SNACKBAR_BACKGROUND_COLOR }}
        >
          Hire Date
        </Typography>
        <TypographyGroup
          typographyList={[
            {
              children: personData.startDate,
              variant: "body1",
              color: "inherit",
            },
            {
              children: getTimeOnJob(personData.startDate, new Date()),
              variant: "body1",
              color: "inherit",
            },
          ]}
        />
      </Box>

      <Box
        data-testid='location-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <hr
          style={{
            backgroundColor: SNACKBAR_BACKGROUND_COLOR,
            height: 5,
            marginRight: "10%",
          }}
        />
        <DataIcon
          iconName={{ icon: "hexagons" }}
          text={{
            children:
              personData.jobInformation?.length > 0
                ? personData.jobInformation[0].department
                : "Not Found",
            variant: "body1",
          }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: "mapPin" }}
          text={{ children: personData.country, variant: "body1" }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: "employmentStatus" }}
          text={{
            children:
              personData.statusCategory?.length > 0
                ? personData.employmentStatus[0].statusCategory
                : "Not Found",
            variant: "body1",
          }}
          columnGap={columnGap}
        />
        <DataIcon
          iconName={{ icon: "docSign" }}
          text={{ children: personData.contractType, variant: "body1" }}
          columnGap={columnGap}
        />
      </Box>

      <Box
        data-testid='reports-to-container'
        sx={{ display: "flex", flexDirection: "column", rowGap: 1 }}
      >
        <hr
          style={{
            backgroundColor: SNACKBAR_BACKGROUND_COLOR,
            height: 5,
            marginRight: "10%",
          }}
        />
        <Typography
          variant='body1'
          sx={{ fontWeight: "bold", color: SNACKBAR_BACKGROUND_COLOR }}
        >
          Reports To
        </Typography>

        {personData.jobInformation?.map((item: Dict, index: number) => {
          const supervisorFullName = joinNameLastName(
            item.supervisorName ?? "Name",
            item.supervisorLastName ?? "LastName"
          );
          return (
            <div key={`dataAvatar-${index}`}>
              <DataAvatar
                text={{
                  children: supervisorFullName,
                  variant: "body1",
                  color: "inherit",
                }}
                avatar={{
                  srcImage: "default",
                  alt: "default",
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
          );
        })}
      </Box>
    </Box>
  );

  return isMobile ? (
    <Drawer open={open} onClose={() => setOpen?.(!open)}>
      {content}
    </Drawer>
  ) : (
    content
  );
}
