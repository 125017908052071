import { Box, DialogContent } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { modalStyle } from "src/constants/personProfile";
import { INotesCategory } from "src/constants/types";
import { createOnSubmitNotes } from "src/utils/createOnSubmit";
import { NotesDataTable } from "src/utils/personProfileDataTable";
import DatePicker from "src/v2/components/atoms/DatePicker/DatePicker";
import { TextField } from "src/v2/components/atoms/TextField";
import CustomAlert from "src/v2/components/molecules/Alert/CustomAlert";
import DialogModal from "src/v2/components/organisms/Dialog/Dialog";
import { UserInformation } from "src/v2/components/organisms/UserInformationTables/UserInformation";
import { DIALOG_BACKGROUND_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";

export function HumanResourcesSection({
  personInformationNoteHR,
  user,
}: {
  personInformationNoteHR?: Dict;
  user: string | undefined;
}) {
  const properties = {
    displayCheckbox: false,
    hidePagination: true,
    hideFooter: true,
    customToolBar: false,
    tableContainer: { maxWidth: "100%" },
  };

  const [alertInformation, setAlertInformation] = useState({
    severity: "success",
    message: "Data saved correctly",
  });
  const [savedData, setSavedData] = useState(false);
  const { personId } = useParams();

  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);
  const [actionButton, setActionButton] = useState("");
  const [actionTitle, setActionTitle] = useState("");
  const [noteBeingEdited, setNoteBeingEdited] = useState({
    id: "",
    date: "",
    author: "",
    category: "",
    note: "",
    section: "HR",
  });
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const objectHR: INotesCategory[] = personInformationNoteHR
    ? Object.values(personInformationNoteHR).filter(
        (object) => object.section === "HR"
      )
    : [];
  const [informationNoteHR, setInformationNoteHR] = useState(objectHR);

  useEffect(() => {
    setInformationNoteHR(objectHR);
  }, [personInformationNoteHR]);

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<INotesCategory>({
    shouldUnregister: true,
  });

  const onSubmit = createOnSubmitNotes({
    onSuccess: () => {
      setAlertInformation({
        severity: "success",
        message: "Data saved correctly",
      });
      setSavedData(true);
    },
    onError: () => {
      setAlertInformation({
        severity: "error",
        message: "Error data was not saved",
      });
      setSavedData(true);
    },
    onSettled: () => {
      handleToggle();
      reset();
    },
  });
  const handleSubmitForm = handleSubmit(async (formData) => {
    const editId = noteBeingEdited.id;
    const noteData = {
      ...formData,
      date: dayjs(formData.createdAt).format("YYYY-MM-DD"),
      section: "HR",
    };
    const notes = isEditingStatus
      ? { [editId]: noteData }
      : { newNote: noteData };
    const dataHR: any = {
      person_id: personId,
      custom_fields: {
        notes: {
          ...notes,
        },
      },
    };
    await onSubmit(dataHR);
  });

  const handleEditClick = (noteId: string) => {
    setNoteBeingEdited(
      informationNoteHR.filter((note) => noteId === note.id)[0]
    );
    setIsEditingStatus(true);
    setActionButton("SAVE");
    setActionTitle("Editing Human Resources Note");
    handleToggle();
  };
  const handleExit = () => {
    handleToggle();
    reset();
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSavedData(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <UserInformation
        title='Human Resources'
        onClick={() => {
          setIsEditingStatus(false);
          setActionButton("SAVE");
          setActionTitle("Human Resources");
          handleToggle();
        }}
        columns={NotesDataTable(handleEditClick, user)}
        rows={informationNoteHR}
        properties={properties}
      />
      <Box data-testid='modal-dialog'>
        <DialogModal
          open={open}
          isSaving={isSubmitting}
          handleCloseAndSave={handleSubmitForm}
          title={actionTitle}
          buttonTitle={actionButton}
          handleExit={handleExit}
          form={
            <DialogContent sx={{ background: DIALOG_BACKGROUND_COLOR }}>
              <div style={modalStyle}>
                <Controller
                  control={control}
                  defaultValue={
                    isEditingStatus ? dayjs(noteBeingEdited.date) : dayjs()
                  }
                  render={({ field: { onChange, value } }) => {
                    const startYear = dayjs(value).add(-5, "year");
                    const endYear = dayjs(value);
                    return (
                      <DatePicker
                        label='Date'
                        value={dayjs(value)}
                        minDate={startYear}
                        maxDate={endYear}
                        sx={{ width: "20%" }}
                        onChange={onChange}
                        disableFuture={!false}
                      />
                    );
                  }}
                  name='createdAt'
                />
              </div>
              <div style={modalStyle}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  defaultValue={user}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id='authorId'
                      variant='outlined'
                      label='Author'
                      size='medium'
                      value={value}
                      read={false}
                      sx={{ width: { md: "100%" } }}
                      onChange={onChange}
                      disabled
                    />
                  )}
                  name='author'
                />
                <Controller
                  control={control}
                  rules={{ required: true }}
                  defaultValue={isEditingStatus ? noteBeingEdited.category : ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id='categoryId'
                      variant='outlined'
                      label='Category'
                      helperText={errors.category ? "Category is required" : ""}
                      value={value}
                      size='medium'
                      read={false}
                      sx={{ width: { md: "100%" } }}
                      onChange={onChange}
                    />
                  )}
                  name='category'
                />
              </div>
              <div style={modalStyle}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  defaultValue={isEditingStatus ? noteBeingEdited.note : ""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id='notesId'
                      variant='outlined'
                      label='Notes'
                      helperText={errors.note ? "Note is required" : ""}
                      size='medium'
                      read={false}
                      value={value}
                      multiline
                      maxRows={5}
                      sx={{ width: { md: "100%" } }}
                      onChange={onChange}
                    />
                  )}
                  name='note'
                />
              </div>
            </DialogContent>
          }
        />
      </Box>

      <CustomAlert
        sx={{ marginLeft: "50px" }}
        text={alertInformation.message}
        severity={
          alertInformation.severity as "error" | "success" | "info" | "warning"
        }
        open={savedData}
        onClose={handleClose}
      />
    </Box>
  );
}
