import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { INavTabs } from "./types";

export default function NavTabs({ labels, tabs, icons }: INavTabs) {
	const [value, setValue] = React.useState("0");

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%", typography: "body1" }}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						variant='fullWidth'
						onChange={handleChange}
						sx={{
							background: "linear-gradient(to right, #FF3131, #FF914D)",
							height: "100px",
							display: "flex",
							alignItems: "flex-end",
						}}
					>
						{labels.map((item, index) => (
							<Tab
								label={item}
								value={index.toString()}
								key={`tab-${index}`}
								icon={index.toString() === value ? <Icon icon={icons![index].icon} /> : <div />}
								iconPosition='start'
								style={{
									display: "flex",
									columnGap: "5%",
									background: index.toString() === value ? "white" : "none",
									color: index.toString() === value ? "#FF5E0A" : "white",
									fontSize: "0.8rem",
									height: "100%",
									fontWeight: "bold",
								}}
							/>
						))}
					</TabList>
				</Box>
				{tabs.map((item, index) => (
					<div key={`tabPanel-${index}`}>
						<TabPanel value={index.toString()}>{item}</TabPanel>
					</div>
				))}
			</TabContext>
		</Box>
	);
}
