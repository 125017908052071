export const TEXTFIELD_BACKGROUND_COLOR = "#FBE3D6";
export const PRIMARY_COLOR = "#FF5E0A";
export const TABLE_COLUMN_HEADER_COLOR = "#fbe3d6";
export const TABLE_ROW_COLOR = "#e8e3e0";
export const SNACKBAR_COLOR = "#FBE3D6";
export const SNACKBAR_BACKGROUND_COLOR = "#FF3849";
export const CLOSE_DIALOG_ICON_COLOR = "#F98E57";
export const DIALOG_BACKGROUND_COLOR = "#D9D9D9";
export const REVIEW_DASHBOARD_COLOR = "#8B7F78";
export const REVIEW_DASHBOARD_BACKGROUND_COLOR = "#E8E8E8";
export const REVIEW_DASHBOARD_TO_DO = "#EF4444";
export const REVIEW_DASHBOARD_IN_PROGRESS = "#EAB308";
export const REVIEW_DASHBOARD_DONE = "#22C55E";
