import { Box } from "@mui/system";
import { TextField } from "src/v2/components/atoms/TextField";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";

export function BankInformationSection(bankAccountInformation: {
  bankAccountInformation: Dict[];
}) {
  const { bankAccountInformation: personBankAccountData } =
    bankAccountInformation;
  const dataIconColumnGap = "1%";

  return (
    <Box
      data-testid='bank-account-information-container'
      sx={{
        width: { lg: "750px", md: "100%" },
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingTop: "2%", paddingBottom: "3%" }}>
        <DataIcon
          iconName={{ icon: "bank" }}
          text={{
            children: "Bank Account Information",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </div>
      {personBankAccountData?.map((item: Dict, index: number) => (
        <div style={{ marginBottom: "2%" }} key={`accountInformation-${index}`}>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='bank-account-information-swift'
          >
            <TextField
              disabled
              id='swiftId'
              variant='outlined'
              label='SWIFT/BIC or ABA-Routing Number'
              value={item.swiftBic ?? ""}
              size='small'
              sx={{ width: { md: "35%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <div
            style={{ marginBottom: "2%" }}
            data-testid='bank-account-information-accountNumber'
          >
            <TextField
              disabled
              id='accountNumberId'
              variant='outlined'
              label='BBAN-Account Number'
              value={item.accountNumber ?? ""}
              size='small'
              sx={{ width: { md: "35%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
          <div data-testid='bank-account-information-beneficiaryName'>
            <TextField
              disabled
              id='beneficiaryNameId'
              variant='outlined'
              label='Beneficiary Name'
              value={item.beneficiaryName ?? ""}
              size='small'
              sx={{ width: { md: "35%" } }}
              onChange={() => {}}
              className='BambooClass'
            />
          </div>
        </div>
      ))}
    </Box>
  );
}
