import { useMutation } from "@tanstack/react-query";
import { mutateReview } from "src/utils/callServiceFunctions";
import { IReview } from "../models";

export function useMutateReview() {
  return useMutation({
    mutationKey: ["lastUpdatedReview"],
    mutationFn: (data: {
      review: IReview;
      personId: string;
      newReview: boolean;
    }) => mutateReview(data.review, data.personId, data.newReview),
  });
}
