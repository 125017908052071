import * as yup from "yup";

export const reviewInformationSchema = yup.object({
  id: yup.string(),
  currentSalary: yup
    .number()
    .min(0, "Please insert a salary")
    .required("This field is required"),
  proposedSalary: yup
    .number()
    .positive("Please insert a salary")
    .min(
      yup.ref("currentSalary"),
      "Proposed salary shouldn't be less than current salary"
    )
    .required("This field is required"),
  currentJobTitle: yup.string().required("This field is required"),
  proposedJobTitle: yup.string().required("This field is required"),
  reviewDate: yup.string().required("This field is required"),
  status: yup.string().required("This field is required"),
  result: yup.string().required("This field is required"),
  comments: yup.string().required("This field is required"),
});
