import { Box } from "@mui/system";
import dayjs from "dayjs";
import DatePicker from "src/v2/components/atoms/DatePicker/DatePicker";
import Select from "src/v2/components/atoms/Select/Select";
import { TextField } from "src/v2/components/atoms/TextField";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { buildSelectOptions } from "src/v2/utils/filterUtils";
import {
  COUNTRY_OPTIONS,
  DEFAULT_NO_SELECTED_OPTION,
  TSHIRT_SIZE_OPTIONS,
} from "src/components/forms/PersonalInfoForm/utils/InputOptions";

export function BasicInformationSection(personInformation: Dict) {
  const { personInformation: personData } = personInformation;
  const dataIconColumnGap = "1%";

  return (
    <Box
      data-testid='basic-information-container'
      sx={{
        width: { lg: "750px", md: "100%" },
        marginBottom: "3%",
      }}
    >
      <div style={{ paddingBottom: "3%" }}>
        <DataIcon
          iconName={{ icon: "userInformation" }}
          text={{
            children: "Basic Information",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-employeeId'
      >
        <TextField
          disabled
          id='employeeId'
          variant='outlined'
          label='Employee ID'
          value={personData.ioetId ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <Box
        sx={{
          width: { lg: "100%", md: "65%" },
          marginBottom: "2%",
          columnGap: "4%",
          display: { md: "flex", sm: "block" },
        }}
        data-testid='basic-information-names'
      >
        <TextField
          disabled
          id='nameId'
          variant='outlined'
          label='Name'
          value={personData.name ?? ""}
          size='small'
          onChange={() => {}}
          sx={{
            marginBottom: { md: "0", sm: "2%" },
          }}
          className='BambooClass'
        />
        <TextField
          disabled
          id='lastNameid'
          variant='outlined'
          label='Last Name'
          value={personData.lastName ?? ""}
          size='small'
          onChange={() => {}}
          className='BambooClass'
        />
      </Box>
      <Box
        sx={{
          marginBottom: "2%",
          columnGap: "4%",
          display: { md: "flex", sm: "block", xs: "block" },
        }}
        data-testid='basic-information-age'
      >
        <DatePicker
          label='Birth date'
          value={dayjs(personData.dateOfBirth)}
          disabled
          slotProps={{
            textField: {
              size: "small",
              sx: {
                "width": { md: "30%" },
                "marginBottom": { md: "0", sm: "2%" },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              },
            },
          }}
        />
        <TextField
          disabled
          id='ageId'
          variant='outlined'
          label='Age'
          value={personData.age ?? ""}
          size='small'
          sx={{ width: { md: "10%" }, marginBottom: { md: "0", sm: "2%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
        <TextField
          id='placeOfBirthId'
          variant='outlined'
          label='Place of birth'
          value={personData.countryOfBirth ?? ""}
          size='small'
          sx={{ width: { md: "60%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </Box>
      <div style={{ marginBottom: "2%" }} data-testid='basic-information-ssn'>
        <TextField
          disabled
          id='ssnId'
          variant='outlined'
          label='SSN'
          value={personData.snn ?? ""}
          size='small'
          sx={{ width: { md: "25%" } }}
          onChange={() => {}}
          className='BambooClass'
          popoverText='Social Security Number (SSN) is a National ID number for United States citizens'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-numberId'
      >
        <TextField
          disabled
          id='idNumerId'
          variant='outlined'
          label='ID number'
          value={personData.cedula ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div style={{ marginBottom: "2%" }} data-testid='basic-information-ruc'>
        <TextField
          disabled
          id='rucId'
          variant='outlined'
          label='RUC'
          value={personData.ruc ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          popoverText='Registro Único de Contribuyentes (RUC) is an identifier for individuals engaged in economic activities in Ecuador'
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-github'
      >
        <TextField
          disabled
          id='gitHubId'
          variant='outlined'
          label='GitHub'
          value={personData.githubId ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-gender'
      >
        <TextField
          disabled
          id='genderId'
          variant='outlined'
          label='Gender'
          value={personData.gender ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-maritalStatus'
      >
        <TextField
          disabled
          id='maritalStatusId'
          variant='outlined'
          label='Marital Status'
          // TODO marital status should be implemented in the backend
          value={personData.maritalStatus ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-address-1'
      >
        <TextField
          disabled
          id='addressStreet1'
          variant='outlined'
          label='Address Street 1'
          value={personData.address1}
          size='small'
          multiline
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-address-2'
      >
        <TextField
          disabled
          id='addressStreet2'
          variant='outlined'
          label='Address Street 2'
          value={personData.address2}
          size='small'
          multiline
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-address-detail'
      >
        <TextField
          disabled
          id='addresDetail'
          variant='outlined'
          label='Address Detail'
          value={personData.addressDetail}
          size='small'
          multiline
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <Box
        sx={{
          marginBottom: "2%",
          columnGap: "4%",
          display: { md: "flex", sm: "block", xs: "block" },
        }}
        data-testid='basic-information-city'
      >
        <TextField
          disabled
          id='cityId'
          variant='outlined'
          label='City'
          value={personData.city ?? ""}
          size='small'
          sx={{
            width: { md: "33%" },
            marginBottom: { md: "0", sm: "2%" },
          }}
          onChange={() => {}}
          className='BambooClass'
        />
        <TextField
          disabled
          id='stateId'
          variant='outlined'
          label='State'
          value={personData.province ?? ""}
          size='small'
          sx={{
            width: { md: "33%" },
            marginBottom: { md: "0", sm: "2%" },
          }}
          onChange={() => {}}
          className='BambooClass'
        />
        <TextField
          disabled
          id='zipCodeId'
          variant='outlined'
          label='Zip Code'
          value={personData.zipCode ?? ""}
          size='small'
          sx={{ width: { md: "33%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </Box>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-country'
      >
        <Select
          label='Country'
          value={personData.country ?? DEFAULT_NO_SELECTED_OPTION}
          disabled
          options={
            buildSelectOptions(personData.country, COUNTRY_OPTIONS)
          }
          size='small'
          sx={{
            "width": { md: "35%" },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          fullWidth
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-mobileId'
      >
        <TextField
          disabled
          id='mobileId'
          variant='outlined'
          label='Mobile'
          value={personData.mobilePhone ?? ""}
          size='small'
          sx={{ width: { md: "25%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-homePhone'
      >
        <TextField
          disabled
          id='homePhoneId'
          variant='outlined'
          label='Home phone'
          value={personData.homePhone ?? ""}
          size='small'
          sx={{ width: { md: "25%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div style={{ marginBottom: "2%" }} data-testid='basic-information-email'>
        <TextField
          disabled
          id='emailId'
          variant='outlined'
          label='Email'
          value={personData.ioetEmail ?? ""}
          size='small'
          sx={{ width: { md: "50%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-personalEmail'
      >
        <TextField
          disabled
          id='personalEmailId'
          variant='outlined'
          label='Personal email'
          value={personData.homeEmail ?? ""}
          size='small'
          sx={{ width: { md: "50%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-linkedIn'
      >
        <TextField
          disabled
          id='linkedinId'
          variant='outlined'
          label='LinkedIn'
          value={personData.linkedIn ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-facebook'
      >
        <TextField
          disabled
          id='facebookId'
          variant='outlined'
          label='Facebook'
          value={personData.facebook ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "2%" }}
        data-testid='basic-information-twitter'
      >
        <TextField
          disabled
          id='twitterId'
          variant='outlined'
          label='Twitter'
          value={personData.twitterFeed ?? ""}
          size='small'
          sx={{ width: { md: "35%" } }}
          onChange={() => {}}
          className='BambooClass'
        />
      </div>
      <div
        style={{ marginBottom: "3%" }}
        data-testid='basic-information-tshirtSize'
      >
        <Select
          label='T-shirt Size'
          value={personData.tShirtSize ?? DEFAULT_NO_SELECTED_OPTION}
          disabled
          options ={
            buildSelectOptions(personData.tShirtSize, TSHIRT_SIZE_OPTIONS)
          }
          size='small'
          sx={{
            "width": { md: "35%" },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          fullWidth
        />
      </div>
    </Box>
  );
}
