import {
  AUTH_URL,
  LOCAL_BACKEND_URL,
  REACT_TYPE_ENV,
  SERVICE_URL,
  SYNC_URL,
} from "src/v2/config/envConstants";

const PEOPLE_LOGIN_URL: { [key: string]: string } = {
  LOCAL: `${AUTH_URL}/authn/login/peopleApp`,
  SERVER: `${SERVICE_URL}/auth/login/peopleApp`,
};
const PEOPLE_LOGOUT_URL: { [key: string]: string | Object | undefined } = {
  LOCAL: LOCAL_BACKEND_URL,
  SERVER: `${SERVICE_URL}/backend/people`,
};
const BACKEND_URL: { [key: string]: string } = {
  LOCAL: LOCAL_BACKEND_URL as string,
  SERVER: `${SERVICE_URL}/backend/people`,
};
const URL_SYNC: { [key: string]: string } = {
  LOCAL: `${SYNC_URL}`,
  SERVER: `${SERVICE_URL}/sync/people/`,
};

const PEOPLE_EMPLOYEE_PROFILE_URL = "/profile_employee/";
const PEOPLE_BFF_PERSON = "/person/";
const PEOPLE_BFF_NOTE = "/note/";
const PEOPLE_BFF_PERSON_FILTERED = "/person/filtered";
const PEOPLE_BFF_REVIEW = "/review/";
const PEOPLE_BFF_NEXT_REVIEW_DATE = "next_review_date/";
const ADMIN_ROLE_NAME = "admin";
const USER_ROLE_NAME = "admin";

const LOGOUT_URL = `${PEOPLE_LOGOUT_URL[REACT_TYPE_ENV as string]}/logout/`;
const LOGIN_URL = PEOPLE_LOGIN_URL[REACT_TYPE_ENV as string];
const BACKEND_URL_ENV = BACKEND_URL[REACT_TYPE_ENV as string];
const SYNC_URL_ENV = URL_SYNC[REACT_TYPE_ENV as string];

export {
  ADMIN_ROLE_NAME,
  BACKEND_URL_ENV,
  LOGIN_URL,
  LOGOUT_URL,
  PEOPLE_BFF_NEXT_REVIEW_DATE,
  PEOPLE_BFF_NOTE,
  PEOPLE_BFF_PERSON,
  PEOPLE_BFF_PERSON_FILTERED,
  PEOPLE_BFF_REVIEW,
  PEOPLE_EMPLOYEE_PROFILE_URL,
  SYNC_URL_ENV,
  USER_ROLE_NAME,
};
