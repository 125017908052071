import { LOGIN_URL } from "src/v2/config/constants";
import people_app_logo_color from "../../assets/people_app_logo_color.png";
import { Button } from "../Button/Button";
import { StyleButton } from "../Button/StyleButton";
import "./LoginStatusContainer.css";
import {
  LoginPageContainer,
  LogoImage,
  LoginCard,
} from "./LoginStatusContainerStyleComponents";

function LoginStatusContainer() {
  return (
    <LoginPageContainer id='animation'>
      <LoginCard>
        <LogoImage src={people_app_logo_color} alt='logo' />
        <StyleButton>
          <Button
            text='Login'
            callback={() => {
              window.location.href = LOGIN_URL;
            }}
          />
        </StyleButton>
      </LoginCard>
    </LoginPageContainer>
  );
}

export default LoginStatusContainer;
