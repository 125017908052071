/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from "@tanstack/react-query";
import {
  fetchNextReviewDate,
  fetchPersonData,
  getAllPeopleData,
  listAllPeople,
  listAllReviewsByPersonId,
  listAllReviewsPerDateDashboard,
} from "src/utils/callServiceFunctions";

export function usefetchPersonData(personId: string) {
  const { isPending, error, data } = useQuery({
    queryKey: ["fetchPersonData"],
    queryFn: () => fetchPersonData(personId),
  });
  return { isPending, error, data };
}
export function uselistAllPeople() {
  const { isPending, error, data } = useQuery({
    queryKey: ["listAllPeople"],
    queryFn: () => listAllPeople(),
  });
  return { isPending, error, data };
}

export function useListAllReviewsByPersonId(personId: string) {
  const { isPending, error, data, refetch, isRefetching } = useQuery({
    queryKey: ["listAllReviewsByPersonId", personId],
    queryFn: () => listAllReviewsByPersonId(personId),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = isPending || isRefetching;
  return { isLoading, error, data, refetch };
}

export function useFetchNextReviewDateByPersonId(personId: string) {
  const { isPending, error, data, isRefetching } = useQuery({
    queryKey: ["fetchNextReviewDate", personId],
    queryFn: () => fetchNextReviewDate(personId),
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  const isLoading = isPending || isRefetching;
  return { isLoading, error, data };
}

export function useGetReviewsDashboard(date: string) {
  const { isPending, error, data } = useQuery({
    queryKey: ["getReviewsDashboard", date],
    queryFn: () => listAllReviewsPerDateDashboard(date),
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { isPending, error, data };
}

export function useGetAllPeopleData() {
  const { isPending, error, data } = useQuery({
    queryKey: ["getAllPeopleData"],
    queryFn: () => getAllPeopleData(),
  });
  return { isPending, error, data };
}
