import { Dict } from "src/v2/models/dynamicModel.model";
import { sanitizeArray } from "./parsers";

export const filterColumns = (data: Dict[], headerMap: { [key: string]: string }) => {
	const orderedFilter = sanitizeArray(data).map((obj) => {
		const orderedObj: Dict = {};

		Object.keys(headerMap).forEach((key) => {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				orderedObj[key] = obj[key];
			}
		});

		return orderedObj;
	});

	return orderedFilter;
};
