import { IFilterAttributes } from "@components/organisms/Filter/types";
import { SelectChangeEvent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import {
  DynamicPersonAdapter,
  filterRows,
  OPERATOR_OPTIONS,
  updateColumns,
} from "src/v2/utils/filterUtils";

export function useFilter<T>(
  columns: GridColDef[],
  currentRows: T[],
  setFilterRow: (rows: T[]) => void
) {
  const [open, setOpen] = useState<boolean>(false);
  const [filterAttributes, setFilterAttributes] = useState<IFilterAttributes>(
    {}
  );
  const [operator, setOperator] = useState<string>("contains");
  const [columnsToFilter, setColumnsToFilter] = useState<GridColDef[]>(columns);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const filterHeaders = Object.keys(filterAttributes);
  const filters = columns.map((column) => ({
    key: column.field,
    headerName: column.headerName ?? "",
  }));

  useEffect(() => {
    updateColumns(columns, filterHeaders, setColumnsToFilter);
    const newRows = filterRows(
      currentRows.map((person) => DynamicPersonAdapter.adapt(person)),
      filters,
      filterAttributes,
      currentRows
    );
    setFilterRow(newRows.length > 0 ? newRows : []);
  }, [filterAttributes]);

  const handleColumnSelection = (value: string) => {
    if (value) {
      setFilterAttributes({
        ...filterAttributes,
        [value]: { value: "", filterOperator: "", openPopover: false },
      });
    }
    setOpen(!open);
  };

  const handleClose = (filter: string) => {
    setFilterAttributes({
      ...filterAttributes,
      [filter]: { ...filterAttributes[filter], openPopover: false },
    });
  };

  const handleOpenData = (
    filter: string,
    event: MouseEvent<HTMLButtonElement> | null
  ) => {
    setFilterAttributes({
      ...filterAttributes,
      [filter]: { ...filterAttributes[filter], openPopover: true },
    });
    setAnchorEl(event?.currentTarget as HTMLButtonElement);
  };

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setOperator(value);
  };

  const handleDeleteFilter = (filter: string) => {
    const filterAttributesToDelete = { ...filterAttributes };

    const deletedFilterAttributes = delete filterAttributesToDelete[filter]
      ? { ...filterAttributesToDelete }
      : { ...filterAttributes };
    setFilterAttributes(deletedFilterAttributes);
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilterAttributes((prev: IFilterAttributes) => {
      const newFilterAttributes: IFilterAttributes = {
        ...prev,
        [name]: {
          value: value.toLowerCase(),
          filterOperator: operator,
          openPopover: true,
        },
      };

      return newFilterAttributes;
    });
  };

  const handleResetFilters = () => {
    setFilterAttributes({});
    setOpen(false);
    setOperator(OPERATOR_OPTIONS[0].value);
    setColumnsToFilter(columns);
  };

  const handlePopover = (newState: boolean) => {
    setOpen(newState);
  };

  return {
    open,
    filterAttributes,
    operator,
    columnsToFilter,
    anchorEl,
    handleColumnSelection,
    handleClose,
    handleOpenData,
    handleOperatorChange,
    handleDeleteFilter,
    handleInput,
    handleResetFilters,
    handlePopover,
    filterHeaders,
  };
}
