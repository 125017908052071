/* eslint-disable react/function-component-definition */
import React from "react";
import { IoetIcon } from "@icons";
import { Button as MuiButton } from "@mui/material";
import { Icon } from "src/v2/components/atoms/Icon";
import { IButtonProps } from "./types";
import { Typography } from "../Typography";

const Button: React.FC<IButtonProps> = ({
  variant = "contained",
  color = "primary",
  size = "medium",
  iconPosition,
  icon,
  disabled = false,
  disableElevation = false,
  text,
  onClick,
  iconStyle,
  sx,
}) => {
  const iconType = () => {
    if (typeof icon === "string") {
      return <Icon icon={(icon as IoetIcon) || "default"} style={iconStyle} />;
    }
    return icon;
  };
  return (
    <MuiButton
      variant={variant ?? "contained"}
      color={color ?? "primary"}
      size={size ?? "medium"}
      startIcon={iconPosition === "left" ? iconType() : null}
      endIcon={iconPosition === "right" ? iconType() : null}
      disabled={disabled ?? false}
      disableElevation={disableElevation ?? false}
      onClick={onClick}
      sx={{ textTransform: "none", ...sx }}
    >
      <Typography variant='button' sx={{ textTransform: "none" }}>
        {text}
      </Typography>
    </MuiButton>
  );
};

export default Button;
