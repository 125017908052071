import axios from "axios";
import { createContext, useMemo } from "react";
import { BACKEND_URL_ENV } from "src/v2/config/constants";
import User from "../types/userType";
import { IAuthContext } from "./IContexts";

const AuthContext = createContext<IAuthContext | undefined>({
  getUserInformation: async () => undefined,
});

export function AuthProvider({ children }: { children: JSX.Element }) {
  async function getUserInformation(): Promise<User | undefined> {
    try {
      const res = await axios.get(`${BACKEND_URL_ENV}/user_information/`, {
        withCredentials: true,
      });
      if (res.status === 200) return res.data as User;
      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  const contextValue = useMemo(() => ({ getUserInformation }), []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;
