import { Button as ButtonMUI, ButtonProps } from "@mui/material";
import { IButtonProps } from "./IButton";
import { styles } from "./StyleButton";

export function Button(props: IButtonProps) {
	const { children, type = "button", variant = "text", rounded, sx, ...rest } = props;

	const currentStyle = styles[type];
	const buttonType: ButtonProps["type"] = type === "submit" || type === "reset" ? type : "button";

	return (
		<ButtonMUI
			variant={variant}
			type={buttonType}
			sx={[currentStyle, { borderRadius: rounded ? "50px" : "0", textTransform: "none", ...sx }]}
			{...rest}
		>
			{children}
		</ButtonMUI>
	);
}
