import { Box, Divider } from "@mui/material";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { CareerPathSection } from "./CareerPathSection/CareerPathSection";
import { ClientProjectSection } from "./ClientProjectSection/ClientProjectSection";
import { HumanResourcesSection } from "./HumanResourcesSection/HumanResourcesSection";

interface INotesTabProps {
  personInformationNotes: Dict;
  key: string;
  user: string | undefined;
}

export function NotesTab({ personInformationNotes, user }: INotesTabProps) {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 5, maxWidth: "xl" }}
    >
      <HumanResourcesSection
        personInformationNoteHR={personInformationNotes}
        user={user}
      />
      <Divider sx={{ borderWidth: "medium" }} color={PRIMARY_COLOR} />
      <ClientProjectSection
        personInformationNoteClient={personInformationNotes}
        user={user}
      />
      <Divider sx={{ borderWidth: "medium" }} color={PRIMARY_COLOR} />
      <CareerPathSection
        personInformationNoteCareer={personInformationNotes}
        user={user}
      />
    </Box>
  );
}
