export const addEntryButtonStyles = {
  size: "4",
  color: "#FF8B4B",
  background: "#FFFFFF",
  state: "enable",
  variant: "outlined",
  textTransform: "none",
  boxShadow: 3,
  borderRadius: 2,
  width: "8rem",
  height: "3rem",
  gridColumnStart: 3,
};
