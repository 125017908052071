import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { yearMonthDate } from "src/utils/parsers";
import { Link } from "react-router-dom";
import { PEOPLE_BFF_PERSON } from "src/constants/urlConstants";
import { ReviewStatus } from "src/constants/reviewsConstants";
import {
  REVIEW_DASHBOARD_DONE,
  REVIEW_DASHBOARD_IN_PROGRESS,
  REVIEW_DASHBOARD_TO_DO,
} from "../constants/colors";

export const reviewsDashboardTable = () => {
  const columns: GridColDef[] = [
    {
      field: "ioetId",
      headerName: "Ioet ID",
      type: "string",
      sortable: true,
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      sortable: true,
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Link
            to={PEOPLE_BFF_PERSON + params.row.ioetId}
            target='_blank'
            rel='noopener noreferrer'
          >
            {params.row.name}
          </Link>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      type: "string",
      sortable: true,
      flex: 0.8,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      sortable: true,
      flex: 0.7,
      renderCell: (params) => {
        if (params.row.status) {
          const statusColor: Record<ReviewStatus, string> = {
            [ReviewStatus.toDo]: REVIEW_DASHBOARD_TO_DO,
            [ReviewStatus.inProgress]: REVIEW_DASHBOARD_IN_PROGRESS,
            [ReviewStatus.done]: REVIEW_DASHBOARD_DONE,
          };
          return (
            <Stack
              display='flex'
              alignItems='center'
              justifyContent='center'
              spacing={2}
              direction='row'
              padding={2}
              key={params.row.id}
            >
              <div
                className='h-2 w-2 rounded-full'
                style={{
                  backgroundColor:
                    statusColor[params.row.status as ReviewStatus],
                }}
              />
              <p>{params.row.status}</p>
            </Stack>
          );
        }
        return <div>status type not found</div>;
      },
    },
    {
      field: "reviewDate",
      headerName: "Review Date",
      sortable: true,
      type: "string",
      flex: 0.4,
      renderCell: (params) => {
        params.row.reviewDate = yearMonthDate(params.row.reviewDate);
        return params.row.reviewDate;
      },
    },
  ];
  return columns;
};
