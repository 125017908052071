import { useNavigate } from "react-router-dom";
import peopleLogo from "../../assets/logo_white.png";
import { PEOPLE_HOME_URL } from "../../constants/urlConstants";
import { SearchBar } from "../../v2/components";
import { TabButton } from "../TabButton/TabButton";
import { UserInformationPopover } from "../../v2/components/organisms/UserInformationPopover";
import {
  LogoAndButtonsContainer,
  NavBarContainer,
  NavBarInnerContainer,
  NavBarLogoContainer,
  TabButtonNavBarContainer,
} from "./NavBarStyleComponents";

export function NavBar() {
  const navigate = useNavigate();

  return (
    <NavBarContainer>
      <NavBarInnerContainer>
        <LogoAndButtonsContainer>
          <NavBarLogoContainer>
            <a href='/'>
              <img src={peopleLogo} alt='Logo' className='max-h-16' />
            </a>
          </NavBarLogoContainer>
          <TabButtonNavBarContainer data-testid='tab-button-nav-bar-container'>
            <TabButton text='Home' callback={() => navigate(PEOPLE_HOME_URL)} />
          </TabButtonNavBarContainer>
        </LogoAndButtonsContainer>
        <SearchBar />
        <UserInformationPopover />
      </NavBarInnerContainer>
    </NavBarContainer>
  );
}
