import httpClient from "../config/httpClient";
import { PEOPLE_BFF_PERSON, PEOPLE_BFF_NOTE } from "../config/constants";
import { DynamicModel } from "../models/dynamicModel.model";

function generateMockData(): DynamicModel {
  return {
    values: {
      personList: [
        // { id: "ioet", name: "John Carter", ioetLevel: "ioet level" },
      ],
      month: "January",
      notification_number: 0,
    },
  };
}
// Obs: The backend handles the data filtering based on role, but the frontend always need to tell it in what view the user is
export async function fetchAllPeopleData(): Promise<DynamicModel[]> {
  const response = await httpClient.peopleBff.get(PEOPLE_BFF_PERSON);
  const people: DynamicModel[] = response.data.response.map((item: Object) => {
    const person: DynamicModel = { values: item };
    return person;
  });
  return people;
}

export async function updateOnePersonData(newData: any) {
  const response = await httpClient.peopleBff.put(PEOPLE_BFF_PERSON, newData);
  return response;
}

export async function getPersonInformation(ioetId: string) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}${ioetId}`
  );
  return response.data;
}

export async function fetchAllTimeNotifications(): Promise<DynamicModel> {
  const people = generateMockData();
  return Promise.resolve(people);
}

export async function SyncAllData(): Promise<{ success: boolean }> {
  const response = await httpClient.peopleSync.get("/api/v1/sync-data");
  return response.data;
}

export async function updateNoteData(newData: any) {
  const response = await httpClient.peopleBff.put(PEOPLE_BFF_NOTE, newData);
  return response;
}

export async function getCustomFieldById(personId: any) {
  try {
    const response = await httpClient.peopleBff.get(
      `${PEOPLE_BFF_PERSON}note/${personId}`
    );
    return response.data.notes;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("There are no notes: ", error);
    return {};
  }
}
