import { useContext } from "react";
import { TabsUpdateContext } from "../contexts/TabUpdateContext";

export const useTabsUpdate = () => {
  const context = useContext(TabsUpdateContext);
  if (!context) {
    throw new Error("Modal must be used within a ModalProvider");
  }
  return context;
};
