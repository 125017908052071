import { Checkbox as MUICheckbox } from "@mui/material";
import { ICheckboxProps } from "./types";

function Checkbox({
  checked,
  checkedIcon,
  color,
  disabled,
  icon,
  onChange,
  sx,
}: ICheckboxProps) {
  return (
    <MUICheckbox
      checked={checked}
      checkedIcon={checkedIcon}
      color={color}
      disabled={disabled}
      icon={icon}
      onChange={onChange}
      sx={sx}
    />
  );
}
export default Checkbox;
