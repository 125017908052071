import { uperCaseConverter } from "src/utils/stringJoins";
import {
  PEOPLE_BFF_NEXT_REVIEW_DATE,
  PEOPLE_BFF_PERSON,
  PEOPLE_BFF_REVIEW,
} from "../config/constants";
import httpClient from "../config/httpClient";
import { IReview } from "../models";
import { IReviewDashboard } from "../models/review.model";

export async function getReviewsDashboard(
  date: string
): Promise<IReviewDashboard[]> {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}last_reviews/${date}`
  );
  const transformedReviews: IReviewDashboard[] = response.data.lastReviews.map(
    (review: any) => {
      return {
        id: review.person_id,
        ioetId: review.person_id,
        name: review.name,
        reviewDate: review.next_review_date,
        status: review.status,
        jobTitle: review.current_job,
      };
    }
  );
  return transformedReviews as unknown as IReviewDashboard[];
}

export async function getReviewsByPersonId(personId: string | undefined) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON}review/${personId}`
  );
  return response.data.reviews;
}

export async function createReview(
  newReview: IReview,
  personId: string | undefined
) {
  const parsedReview = {
    person_id: personId,
    review: {
      review_date: newReview.reviewDate,
      status: newReview.status,
      current_salary: +newReview.currentSalary.toFixed(2),
      proposed_salary: +newReview.proposedSalary.toFixed(2),
      current_job: newReview.currentJobTitle,
      proposed_job: newReview.proposedJobTitle,
      comments: { comment: newReview.comments },
      result: newReview.result,
    },
  };
  const response = await httpClient.peopleBff.post(
    PEOPLE_BFF_REVIEW,
    parsedReview
  );
  return response;
}

export async function updateReview(
  updatedReview: IReview,
  personId: string | undefined
) {
  const parsedReview = {
    person_id: personId,
    review: {
      id: updatedReview.id,
      review_date: updatedReview.reviewDate,
      status: updatedReview.status,
      current_salary: +updatedReview.currentSalary.toFixed(2),
      proposed_salary: +updatedReview.proposedSalary.toFixed(2),
      current_job: updatedReview.currentJobTitle,
      proposed_job: updatedReview.proposedJobTitle,
      comments: { comment: updatedReview.comments },
      result: updatedReview.result,
    },
  };
  const response = await httpClient.peopleBff.put(
    PEOPLE_BFF_REVIEW,
    parsedReview
  );
  return response;
}

export async function getNextReviewDateByPersonId(personId: string) {
  const response = await httpClient.peopleBff.get(
    `${PEOPLE_BFF_PERSON + PEOPLE_BFF_NEXT_REVIEW_DATE + personId}/`
  );
  return response.data.nextReviewDate;
}
