import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ILoadingContextState } from "src/contexts/IContexts";
import LoadingContext from "src/contexts/LoadingStateContext";
import { INotes, getNotesByID } from "src/utils/callServiceFunctions";
import { Avatar } from "src/v2";
import { SideBarProfile } from "src/v2/components/SideBarProfile/sideBarProfile";
import NavTabs from "src/v2/components/organisms/NavTabs/NavTabs";
import { TEXTFIELD_BACKGROUND_COLOR } from "src/v2/constants/colors";
import { usefetchPersonData } from "src/v2/hooks/useFetchs";
import { useTabsUpdate } from "src/v2/hooks/useTabsUpdate";
import { CompensationTab } from "src/v2/pages/PersonProfile/PersonProfileTabs/CompensationTab/CompensationTab";
import { themePeopleApp } from "src/v2/theme/provider/ThemePeopleApp";
import useAuth from "../../../hooks/useAuth";
import User from "../../../types/userType";
import { Header } from "../../components/Header/header";
import Loading from "../../components/Loading/Loading";
import { CareerTab } from "./PersonProfileTabs/CareerTab/CareerTab";
import { NotesTab } from "./PersonProfileTabs/NotesTab/NotesTab";
import { PersonalTab } from "./PersonProfileTabs/PersonalTab/PersonalTab";

const DEFAULT_TABS = [
  "Personal",
  "Career",
  "Compensation",
  "ioet University",
  "Notes",
];

export function PersonProfile() {
  const { personId } = useParams();
  const { loading } = useContext(LoadingContext) as ILoadingContextState;
  const [user, setUser] = useState<User | undefined>(undefined);
  const { getUserInformation } = useAuth();
  const { data: personInformation, isPending } = usefetchPersonData(personId!);

  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState<INotes>({});
  const {
    notes: { NotesUpdate, setNotesUpdate },
  } = useTabsUpdate();

  useEffect(() => {
    getUserInformation().then((userInfo) => {
      setUser(userInfo);
    });

    const getNotes = async () => {
      const response = await getNotesByID(personId!);
      setNotes(response);
    };
    getNotes();
  }, []);

  useEffect(() => {
    const getNotes = async () => {
      const response = await getNotesByID(personId!);
      setNotes(response);
    };
    getNotes();
    setNotesUpdate(false);
  }, [NotesUpdate]);

  if (isPending || loading || !user) {
    return <Loading />;
  }
  return (
    <Box
      data-testid='person-profile'
      style={{
        display: "grid",
        gridTemplateColumns: "300px minmax(0, 1fr)",
        gridTemplateRows: "150px 100px auto",
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <Box
        data-testid='avatar-container'
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gridColumnStart: 1,
          gridRowStart: 1,
          gridRowEnd: { xs: 1, lg: 3 },
          background: "#FF3131",
          p: 2,
        }}
      >
        <Avatar
          alt={personInformation.name}
          height='100%'
          width='auto'
          srcImage='https://t4.ftcdn.net/jpg/03/31/69/91/360_F_331699188_lRpvqxO5QRtwOM05gR50ImaaJgBx68vi.jpg'
        />
      </Box>

      <Header
        data-testid='header-container'
        personInformation={personInformation}
        setOpen={setOpen}
        open={open}
      />

      <Box
        sx={{
          gridColumnStart: 1,
          gridRowStart: 3,
          backgroundColor: TEXTFIELD_BACKGROUND_COLOR,
          height: "100%",
          display: { xs: "none", lg: "block" },
        }}
      >
        <SideBarProfile
          data-testid='side-bar-profile-container'
          personInformation={personInformation}
          setOpen={setOpen}
          open={open}
        />
      </Box>

      <Box
        data-testid='nav-tabs-container'
        sx={{
          width: "100%",
          gridColumnStart: { xs: 1, lg: 2 },
          gridColumnEnd: 3,
          gridRowStart: 2,
          gridRowEnd: 4,
        }}
      >
        <ThemeProvider theme={themePeopleApp}>
          <NavTabs
            labels={DEFAULT_TABS}
            tabs={[
              <PersonalTab
                personInformation={personInformation}
                key='personal-tab'
              />,
              <CareerTab personInformation={personInformation} key='job-tab' />,
              <CompensationTab
                personInformation={personInformation}
                key='compensation-tab'
              />,
              <>section4</>,
              <NotesTab
                personInformationNotes={notes}
                key='notes-tab'
                user={user?.name}
              />,
            ]}
            icons={[
              { icon: "clipboard" },
              { icon: "job" },
              { icon: "compensation" },
              { icon: "graduation" },
              { icon: "notes" },
            ]}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
}
