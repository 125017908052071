import React from "react";
import { filterColumns } from "src/utils/filterColumns";
import Button from "src/v2/components/atoms/Button/Button";
import Icon from "src/v2/components/atoms/Icon/Icon";
import Select from "src/v2/components/atoms/Select/Select";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { Table } from "src/v2/components/organisms/Table/Table";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import { Dict } from "src/v2/models";
import { Compensation } from "src/v2/models/compensation.model";

export function CompensationHistorySection(compensationInformation: {
  compensationInformation: Compensation[];
}) {
  const { compensationInformation: personCompensationData } =
    compensationInformation;
  const dataIconColumnGap = "1%";
  const payGroupOptions = [
    { label: "ioet Inc.", value: "ioet Inc." },
    { label: "ioet Ecuador SAS", value: "ioet Ecuador SAS" },
  ];
  const headerCompensationMap: { [key: string]: string } = {
    effectiveDate: "Effective Date",
    payFrequency: "Pay Schedule",
    payRate: "Salary",
    changeReason: "Change Reason",
    comment: "Comment",
  };
  const personCompensationFilter = filterColumns(
    personCompensationData as Dict[],
    headerCompensationMap
  );
  const columns = React.useMemo(() => {
    const keys =
      personCompensationFilter.length > 0
        ? Object.keys(personCompensationFilter[0])
        : [];
    const existingColumns = keys.map((key) => ({
      field: key,
      headerName: headerCompensationMap[key],
      minWidth: 250,
    }));
    const actionColumn = {
      field: "action",
      headerName: "Action",
      minWidth: 250,
      renderCell: () => <Icon icon='pencil' />,
    };
    return [...existingColumns, actionColumn];
  }, [personCompensationFilter]);

  const rows = React.useMemo(
    () =>
      personCompensationData.map((item, index) => ({
        id: index,
        ...item,
      })),
    [personCompensationFilter]
  );
  return (
    <div style={{ height: "100vh" }}>
      <div style={{ paddingTop: "2%", paddingBottom: "3%", maxWidth: "805px" }}>
        <DataIcon
          iconName={{ icon: "compensation" }}
          text={{
            children: "Compensation History",
            variant: "h6",
            sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
          }}
          columnGap={dataIconColumnGap}
        />
      </div>
      <Select
        label='Pay Group'
        value='ioet Ecuador SAS'
        options={payGroupOptions}
        sx={{ width: "235px" }}
      />
      <div
        role='table'
        style={{ paddingTop: "2%", paddingBottom: "3%", width: "90%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "2%",
            paddingRight: "5%",
          }}
        >
          <Button text='+ Add Entry' variant='outlined' />
        </div>
        <Table
          columns={columns}
          rowData={rows}
          isCustomToolBar={false}
          hideFooter
        />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "2%",
            paddingRight: "5%",
          }}
        >
          <Button text='Show more...' variant='outlined' />
        </div>
      </div>
    </div>
  );
}
