import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ReviewStatus } from "src/constants/reviewsConstants";
import { reviewInformationSchema } from "src/schemas/reviewInformationSchema";
import { useCreateOnSubmitReviews } from "src/utils/createOnSubmit";
import {
  Button,
  Checkbox,
  DatePicker,
  Select,
  TextField,
  Typography,
} from "src/v2/components/atoms";
import { IReview } from "src/v2/models";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import {
  ReviewFormProps,
  SelectOptions,
  textFieldConfig,
  typographyStyles,
} from "./types";

function ReviewForm({
  reviewData,
  handleAccordion,
  setAlertInformation,
  setSavedData,
  currentValues,
  nextReviewDate,
  disableGutters = false,
  isPastReview,
}: ReviewFormProps) {
  const { personId } = useParams();
  const dateSubtractMonth = dayjs().subtract(1, "month");
  const defaultReviewData: IReview = {
    id: "",
    proposedJobTitle: "",
    currentJobTitle:
      currentValues?.currentJobTitle ??
      (!isPastReview ? "Job tittle not found" : ""),
    currentSalary: currentValues?.currentSalary ?? 0,
    proposedSalary: 0,
    status: !isPastReview ? ReviewStatus.inProgress : ReviewStatus.done,
    result: "",
    reviewDate:
      nextReviewDate?.format("YYYY-MM-DD").toString() ??
      (isPastReview
        ? dateSubtractMonth.format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD")),
    comments: "",
  };

  const titles = isPastReview
    ? { recentFieldTitle: "Previous", upcomingFieldTitle: "New" }
    : { recentFieldTitle: "Current", upcomingFieldTitle: "Proposed" };

  const selectMenuProps = { sx: { maxHeight: "30rem" } };

  const {
    reset,
    resetField,
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IReview>({
    defaultValues: reviewData ?? defaultReviewData,
    resolver: yupResolver(reviewInformationSchema),
  });
  let rate = 0;
  if (getValues("currentSalary") && getValues("proposedSalary")) {
    rate = Math.round(
      (getValues("proposedSalary") / getValues("currentSalary") - 1) * 100
    );
  }
  const [openCancelButton, setOpenCancelButton] = useState(false);
  const [openConfirmationButton, setOpenConfirmationButton] = useState(false);
  const [raiseChange, setRaiseChange] = useState(rate);
  const [proposedJobTitle, setProposedJobTitle] = useState(
    getValues("proposedJobTitle")
  );

  const confirmationText = `The new salary will be $${getValues(
    "proposedSalary"
  )} and the new job title will be ${
    getValues("proposedJobTitle") || proposedJobTitle
  }.`;

  const calculateSalary = (
    updatedSalary: number,
    updatedPercentage: number
  ) => {
    updatedSalary = Number(updatedSalary);
    const newSalaryValue =
      updatedSalary + (updatedSalary * updatedPercentage) / 100;
    return Number(newSalaryValue.toFixed(2));
  };
  const calculateRaise = (updatedSalary: number) => {
    const newRaiseValue = Math.round(
      (updatedSalary / (getValues("currentSalary") ?? 1) - 1) * 100
    );
    return newRaiseValue;
  };
  const handleStay = () => {
    setOpenConfirmationButton(false);
    setOpenCancelButton(false);
  };
  const handleLeave = () => {
    setOpenCancelButton(false);
    setOpenConfirmationButton(false);
    resetField("status");
    if (!openConfirmationButton) if (handleAccordion) handleAccordion();
  };
  const handleSalary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSalary = Number.isNaN(parseFloat(e.target.value))
      ? 0
      : parseFloat(e.target.value);
    if (newSalary < 0) return;
    const newRaise = calculateRaise(newSalary);
    setValue("proposedSalary", newSalary, { shouldValidate: true });
    if (newRaise < 0) {
      setRaiseChange(0);
      return;
    }
    setRaiseChange(newRaise);
  };
  const handleRaise = (e: React.ChangeEvent<HTMLInputElement>) => {
    const raise = parseInt(e.target.value, 10);
    const newRaise = Number.isNaN(raise) ? 0 : Math.round(raise);
    const newSalary = calculateSalary(
      getValues("currentSalary") ?? 1,
      newRaise
    );
    if (newRaise < 0) return;
    setRaiseChange(newRaise);
    setValue("proposedSalary", newSalary, { shouldValidate: true });
  };
  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue("status", ReviewStatus.done);
      setOpenConfirmationButton(true);
    } else {
      resetField("status");
    }
  };
  const onSubmit = useCreateOnSubmitReviews({
    onSuccess: () => {
      setAlertInformation({
        severity: "success",
        message: "Data saved correctly",
      });
      setSavedData(true);
      if (!openConfirmationButton) if (handleAccordion) handleAccordion();
    },
    onError: () => {
      setAlertInformation({
        severity: "error",
        message: "Error data was not saved",
      });
      setSavedData(true);
    },
    onSettled: () => {
      reset();
    },
    personId,
    newReview: !reviewData,
  });
  const handleSave = () => {
    setProposedJobTitle(getValues("proposedJobTitle"));
    handleSubmit(onSubmit)();
  };

  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 0,
        marginY: 3,
        paddingX: "1.5rem",
      }}
      maxWidth={false}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={6}
        display='flex'
        alignItems='center'
        columns={{ xs: 3, sm: 6, md: 8, lg: 12 }}
        sx={{ marginX: !disableGutters ? 12 : 0 }}
      >
        <Grid xs={3} sm={8} md='auto' lg={12}>
          <Typography sx={typographyStyles}>Review Date</Typography>
        </Grid>
        <Grid xs={3} sm={8} md='auto' lg={12}>
          <Controller
            control={control}
            name='reviewDate'
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label=''
                slotProps={{
                  textField: { helperText: errors.reviewDate?.message },
                }}
                value={dayjs(value)}
                sx={[{ width: "21rem" }, textFieldConfig.sx]}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                views={["month", "year"]}
                onChange={(e) => {
                  const dateValue = e?.format("YYYY-MM-DD");
                  onChange(dateValue);
                }}
                {...(isPastReview
                  ? { maxDate: dateSubtractMonth }
                  : { minDate: dayjs() })}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={5} lg={!isPastReview ? 3 : 4.6}>
          <Typography sx={typographyStyles}>
            {titles.upcomingFieldTitle} Salary
          </Typography>
          <Controller
            control={control}
            name='proposedSalary'
            render={({ field: { value } }) => (
              <TextField
                {...textFieldConfig}
                value={value.toString()}
                onChange={(e) => {
                  handleSalary(e);
                }}
                sx={[
                  { marginBottom: "1.5rem", width: "100%" },
                  textFieldConfig.sx,
                ]}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                helperText={errors.proposedSalary?.message}
                hug='dollarSign'
                type='number'
              />
            )}
          />
        </Grid>
        {!isPastReview && (
          <Grid xs={1} sm={6} md={3} lg={1.6}>
            <Typography sx={typographyStyles}>Raise %</Typography>
            <TextField
              {...textFieldConfig}
              value={raiseChange.toString()}
              onChange={handleRaise}
              disabled={
                getValues("status") === ReviewStatus.done && !isPastReview
              }
              sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
              type='number'
            />
          </Grid>
        )}
        <Grid xs={3} sm={6} md={6} lg={4}>
          <Typography sx={typographyStyles}>
            {titles.recentFieldTitle} Salary
          </Typography>
          <Controller
            control={control}
            name='currentSalary'
            render={({ field: { value, onChange } }) => (
              <TextField
                {...textFieldConfig}
                value={value}
                sx={[
                  {
                    marginBottom: "1.5rem",
                  },
                  textFieldConfig.sx,
                ]}
                helperText={errors.currentSalary?.message}
                disabled={!isPastReview}
                hug='dollarSign'
                type='number'
                onChange={(e) => {
                  const salary = e.target.value;
                  if (Number(salary) >= 0) onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={8} lg={4.6}>
          <Typography sx={typographyStyles}>
            {titles.upcomingFieldTitle} Job Title
          </Typography>
          <Controller
            control={control}
            name='proposedJobTitle'
            render={({ field: { onChange, value } }) => (
              <Select
                label=''
                value={value || proposedJobTitle}
                options={SelectOptions(!!isPastReview)}
                error={errors.proposedJobTitle?.message}
                onChange={onChange}
                disabled={
                  getValues("status") === ReviewStatus.done && !isPastReview
                }
                fullWidth
                size='small'
                sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
                menuProps={selectMenuProps}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={8} lg={4}>
          <Typography sx={typographyStyles}>
            {titles.recentFieldTitle} Job Title
          </Typography>
          <Controller
            control={control}
            name='currentJobTitle'
            render={({ field: { onChange, value } }) => (
              <Select
                label=''
                value={value}
                fullWidth
                size='small'
                onChange={onChange}
                options={SelectOptions(!!isPastReview)}
                error={errors.currentJobTitle?.message}
                sx={[{ marginBottom: "1.5rem" }, textFieldConfig.sx]}
                disabled={!isPastReview}
                menuProps={selectMenuProps}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Typography sx={typographyStyles}>Comments</Typography>
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Controller
            name='comments'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...textFieldConfig}
                value={value}
                onChange={onChange}
                helperText={errors.comments?.message}
                minRows={20}
                multiline
                sx={{ backgroundColor: "#FBE3D6" }}
              />
            )}
          />
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Typography sx={typographyStyles}>Result</Typography>
        </Grid>
        <Grid xs={3} sm={6} md={12}>
          <Controller
            name='result'
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                {...textFieldConfig}
                value={value}
                helperText={errors.result?.message}
                onChange={onChange}
                multiline
                maxRows={10}
                minRows={10}
                sx={{ backgroundColor: "#FBE3D6" }}
              />
            )}
          />
        </Grid>
        <Grid xs={1.5} sm={6} md={12}>
          <Stack direction='row' spacing={3} alignItems='center'>
            <Typography sx={typographyStyles}>Review finished</Typography>
            <Controller
              name='status'
              control={control}
              render={({ field: { value } }) => (
                <Checkbox
                  sx={[
                    { "& .MuiSvgIcon-root": { fontSize: 40 } },
                    { padding: 0 },
                  ]}
                  checked={value === ReviewStatus.done}
                  onChange={handleStatus}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid
        xs={3}
        display='flex'
        justifyContent='right'
        sm={10}
        md={12}
        sx={{ width: "100%" }}
      >
        <Stack direction='row' spacing={3}>
          <Button
            text='Cancel'
            color='inherit'
            size='large'
            onClick={() => setOpenCancelButton(true)}
            disabled={isSubmitting}
            sx={{ width: "5rem" }}
          />
          <Button
            text='Save'
            color='primary'
            size='large'
            onClick={handleSave}
            disabled={isSubmitting}
            sx={{ width: "5rem" }}
          />
        </Stack>
      </Grid>

      <ConfirmDialog
        confirmType={false}
        dialogTitle='Are you sure you want to leave without saving?'
        openCancelButton={openCancelButton}
        handleStay={handleStay}
        handleLeave={handleLeave}
      />
      <ConfirmDialog
        confirmType
        dialogTitle='This review will be tagged as "Done"'
        dialogText={confirmationText}
        openCancelButton={openConfirmationButton}
        handleStay={handleStay}
        handleLeave={handleLeave}
      />
    </Container>
  );
}

export default ReviewForm;
