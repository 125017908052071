import { createTheme } from "@mui/material";

export const calendarTheme = (theme: any) =>
	createTheme({
		...theme,
		components: {
			MuiDateCalendar: {
				styleOverrides: {
					root: {
						height: "290px",
					},
				},
			},
			MuiYearCalendar: {
				styleOverrides: {
					root: {
						height: "210px",
					},
				},
			},
		},
	});
