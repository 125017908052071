import { createTheme } from "@mui/material/styles";
import {
  PRIMARY_COLOR,
  TEXTFIELD_BACKGROUND_COLOR,
} from "src/v2/constants/colors";

export const themePeopleApp = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "background": TEXTFIELD_BACKGROUND_COLOR,
          "WebkitTextFillColor": "#00000099",
          ".BambooClass": {
            ".Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: TEXTFIELD_BACKGROUND_COLOR,
        },
      },
    },
  },
});
