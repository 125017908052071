import React, { Ref, useState, KeyboardEvent } from "react";
import { Box, Button, Container, Drawer, Divider, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { IDynamicViewTagProps } from "src/v2/models/dynamicViewTag.model";
import { sanitizeArray } from "src/utils/parsers";
import { sidebarStyle } from "./sidebarStyle";

type Anchor = "right" | "top" | "bottom" | "left";
type EventDrawer = React.KeyboardEvent | React.MouseEvent;

interface SidebarProps<T extends IDynamicViewTagProps> {
	anchorPosition: Anchor[];
	propsColumnsDependency: {
		label: string;
		value: T[];
	};
}

function ForwardedSidebar<T extends IDynamicViewTagProps>(
	{ anchorPosition, propsColumnsDependency: { label, value: values } }: SidebarProps<T>,
	ref: Ref<HTMLDivElement>
) {
	values = sanitizeArray(values);

	const [stateDirection, setStateDirection] = useState<Record<Anchor, boolean>>({
		right: false,
		top: false,
		bottom: false,
		left: false,
	});

	const toggleDrawer = (anchor: Anchor, open: boolean) => (event: EventDrawer) => {
		if (event.type === "keydown" && (event as KeyboardEvent).key === ("Tab" || "Shift")) {
			return;
		}

		setStateDirection({ ...stateDirection, [anchor]: open });
	};

	// TODO refactor the Stack and ListItem
	const renderListItem = (item: T) => {
		if (typeof item === "object") {
			const propertiesList = Object.keys(item).map((key: string, idx: number) => {
				const propertiesValues = (item as any)[key];

				return (
					<Stack key={key + idx}>
						<ListItem
							onClick={(e) => e.stopPropagation()}
							disableGutters
							aria-labelledby='render-list-sidebarItems'
							secondaryAction={propertiesValues}
							dense
						>
							<ListItemText primary={key} />
							<Divider />
						</ListItem>
					</Stack>
				);
			});

			return propertiesList;
		}

		if (Array.isArray(item)) {
			return (
				<Stack>
					<ListItem onClick={(e) => e.stopPropagation()} disableGutters sx={{ width: 450 }} secondaryAction={item}>
						<ListItemText primary={item} />
						<Divider />
					</ListItem>
				</Stack>
			);
		}

		return "error getting the list";
	};

	const renderList = (anchor: Anchor) => (
		<Container sx={{ height: "100vh" }}>
			<IconButton onClick={toggleDrawer(anchor, false)} sx={sidebarStyle.IconButton}>
				X
			</IconButton>

			<Box
				role='presentation'
				onClick={(e) => {
					e.stopPropagation();
					toggleDrawer(anchor, false)(e);
				}}
				onKeyDown={(e) => {
					e.stopPropagation();
					toggleDrawer(anchor, false)(e);
				}}
				sx={sidebarStyle.renderListStyles}
			>
				<Typography variant='h3' align='center' gutterBottom mt={3}>
					{label.toUpperCase()}
				</Typography>
				<List component={Box} sx={{ width: "100%", margin: "0 auto", bgcolor: "background.paper" }}>
					{values?.map((item: T, index: number) => (
						<Stack key={index} sx={sidebarStyle.listItemStyles}>
							{renderListItem(item)}
							<Divider />
						</Stack>
					))}
				</List>
			</Box>
		</Container>
	);

	return (
		<>
			{anchorPosition.map((anchor, idx) => (
				<Stack key={anchor + idx} ref={ref}>
					<Button onClick={toggleDrawer(anchor, true)} sx={{ textTransform: "lowercase" }}>
						{label}
					</Button>
					<Drawer
						anchor={anchor}
						open={stateDirection[anchor]}
						onClose={toggleDrawer(anchor, false)}
						sx={sidebarStyle.Drawer}
					>
						{renderList(anchor)}
					</Drawer>
				</Stack>
			))}
		</>
	);
}

const Sidebar = React.forwardRef(ForwardedSidebar);

export default Sidebar;
