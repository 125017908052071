import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { SubmitHandler } from "react-hook-form";
import { INotesCategory, INotesClient } from "src/constants/types";
import { useMutateReview } from "src/v2/hooks/useMutations";
import { useTabsUpdate } from "src/v2/hooks/useTabsUpdate";
import { IReview } from "src/v2/models";
import { updateNoteData } from "src/v2/services/peopleData.service";
import { getFormattedDate } from "./parsers";

interface IOnSubmitProps {
  onSuccess: (response: AxiosResponse<any>) => void;
  onError: () => void;
  onSettled: () => void;
  personId?: string;
  newReview?: boolean;
}

export const createOnSubmitNotes = ({
  onSuccess,
  onError,
  onSettled,
}: IOnSubmitProps): SubmitHandler<INotesCategory | INotesClient> => {
  const {
    notes: { setNotesUpdate },
  } = useTabsUpdate();

  return async (data: INotesCategory | INotesClient) => {
    try {
      const response = await updateNoteData(data);
      if (response.status === 200) {
        onSuccess(response);
        setNotesUpdate(true);
      } else {
        onError();
      }
    } catch (error) {
      onError();
    }

    onSettled();
  };
};

const todayDate: string = getFormattedDate();

export const useCreateOnSubmitReviews = ({
  onSuccess,
  onError,
  onSettled,
  personId,
  newReview,
}: IOnSubmitProps): SubmitHandler<IReview> => {
  const queryClient = useQueryClient();
  const { mutate } = useMutateReview();

  return async (data: IReview) => {
    mutate(
      { review: data, personId: personId!, newReview: !!newReview },
      {
        onSuccess: (response: any) => {
          queryClient.invalidateQueries({
            queryKey: ["listAllReviewsByPersonId", personId ?? ""],
          });
          queryClient.invalidateQueries({
            queryKey: ["fetchNextReviewDate", personId ?? ""],
          });
          queryClient.invalidateQueries({
            queryKey: ["getReviewsDashboard", todayDate],
          });
          onSuccess(response);
        },
        onError,
        onSettled,
      }
    );
  };
};
