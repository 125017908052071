import tw, { styled } from "twin.macro";
import { DivWithColor } from "../Popover/IPopover";

export const styles: any = {
	submit: {},
	reset: {},
	optionFilter: {
		color: "#7E7D7A",
		margin: "5px",
		borderRadius: "40px",
		border: "2px solid #DFDFDE",
		width: "fit-content",
		textTransform: "Capitalize",
		padding: "0px 10px",
	},
	add: {
		"border": "1px solid #7A197A",
		"borderRadius": "4px",
		"backgroundColor": "#7A197A",
		"color": "white",
		"textAlign": "center",
		"width": "fit-content",
		"margin": "4px",
		"&:hover": {
			border: "1px solid #7A197A",
			backgroundColor: "white",
			color: "#7A197A",
			animation: "pulse 0.5s infinite",
		},
		"@keyframes pulse": {
			"0%, 100%": {
				opacity: 1,
			},
			"50%": {
				opacity: 0.5,
			},
		},
	},
	button: {
		fontSize: "1rem",
	},
	addEntry: {
		size: "4",
		color: "#FF8B4B",
		background: "#FFFFFF",
		state: "enable",
		variant: "outlined",
		textTransform: "none",
	},
};

export const SaveButton = tw.div`
    border
    border-[#30BCED]
    rounded
    bg-[#30BCED]
    text-white
    text-center
    w-fit
    my-4
    hover:(border-[#7A197A] bg-white text-[#7A197A] animate-pulse duration-500)
    `;

export const StyleTableActionButton = tw.div`
    border
    border-ioet-green
    rounded
    bg-ioet-green
    text-white
    text-center
    w-fit
    my-1
    hover:(border-ioet-green bg-white text-ioet-green animate-pulse duration-500)
    flex
    items-center
    px-1
    `;

export const LogoutButtonContainer = tw.div`
    border
    border-ioet-purple
    bg-white
    rounded
    hover:bg-ioet-purple
    hover:text-white
    my-4
    `;

export const StyleOptionFilterButton = tw.div`
    text-[#7E7D7A]
    m-5
    rounded-full
    border-2 border-[#DFDFDE]
    inline-block
    uppercase
    px-10
  `;

export const StyleEditButton = tw.div`
    border
    border-[#7A197A]
    rounded
    bg-[#7A197A]
    text-white
    text-center
    w-fit
    hover:(border-[#7A197A] bg-white text-[#7A197A] animate-pulse duration-500)
    my-1
    text-xs
    `;

export const StyleCandidateButton = styled.div(({ color }: DivWithColor<object>) => ({
	"width": "200px",
	"border": `1px solid ${color}`,
	"borderRadius": "5px",
	"backgroundColor": `${color}`,
	"color": "white",
	":hover": {
		border: `1px solid ${color}`,
		backgroundColor: "white",
		color: `${color}`,
		animation: "pulse 1s infinite",
	},
	"height": "fit-content",
}));
