import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "@mui/material";
import { IDatePickerProps } from "./types";
import { calendarTheme } from "./StyleDatePicker";

const DatePicker: React.FC<IDatePickerProps> = ({
	label,
	value,
	disabled,
	closeOnSelect,
	defaultValue,
	onChange,
	onAccept,
	open,
	minDate,
	maxDate,
	onOpen,
	onClose,
	sx,
	views,
	slotProps = {},
	disableFuture = false,
	slots = {},
}) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<ThemeProvider theme={calendarTheme}>
				<MuiDatePicker
					label={label}
					value={value}
					onAccept={onAccept}
					onChange={onChange}
					disabled={disabled}
					closeOnSelect={closeOnSelect}
					defaultValue={defaultValue}
					minDate={minDate}
					maxDate={maxDate}
					onOpen={onOpen}
					onClose={onClose}
					open={open}
					sx={sx}
					yearsPerRow={3}
					slotProps={slotProps}
					views={views}
					disableFuture={disableFuture}
					slots={slots}
				/>
			</ThemeProvider>
		</LocalizationProvider>
	);
};
export default DatePicker;
