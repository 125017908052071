/* eslint-disable react/function-component-definition */
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { IBackDropProps } from "./types";

const BackDrop: React.FC<IBackDropProps> = ({ sx, openBackdrop, handleClose }) => {
	return (
		<Backdrop sx={sx} open={openBackdrop} onClick={handleClose}>
			<CircularProgress color='inherit' />
		</Backdrop>
	);
};
export default BackDrop;
