import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { Box, IconButton } from "@mui/material";
import { joinNameLastName, objectArrayToString } from "src/utils/stringJoins";
import PersonHeaderData from "src/v2/components/organisms/PersonHeaderData/PersonHeaderData";
import { Dict } from "src/v2/models";

export interface IHeaderProps {
	personInformation: Dict;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
	open?: boolean;
}

export function Header(props: IHeaderProps) {
	const { personInformation: personData, setOpen, open } = props;

	const peopleFullName = joinNameLastName(personData.name, personData.lastName);

	return (
		<header
			data-testid='header-container'
			style={{ display: "flex", background: "linear-gradient(to right, #FF3131, #FF914D)" }}
		>
			<Box
				data-testid='typography group'
				sx={{
					width: "80%",
					paddingTop: "1%",
					paddingLeft: "2%",
					display: "flex",
					alignItems: "start",
					justifyContent: "space-between",
				}}
			>
				<PersonHeaderData
					name={peopleFullName}
					jobData={[
						{ children: personData.ioetId, variant: "h6", color: "common.white" },
						{
							children:
								personData.jobInformation && personData.jobInformation[0]
									? personData.jobInformation[0].jobTitle
									: "",
							variant: "h6",
							color: "common.white",
						},
						{ children: objectArrayToString(personData.status), variant: "h6", color: "common.white" },
					]}
				/>

				<IconButton sx={{ display: { lg: "none" } }} onClick={() => setOpen?.(!open)}>
					<MenuOpenIcon sx={{ color: "white" }} />
				</IconButton>
			</Box>
		</header>
	);
}
