import { Box } from "@mui/material";
import { Button } from "src/v2/components/Button/Button";
import { Table } from "src/v2/components/organisms/Table/Table";
import { PRIMARY_COLOR } from "src/v2/constants/colors";
import DataIcon from "src/v2/components/molecules/DataIcon/DataIcon";
import { IUserInformation } from "./types";

export function getAddEntryButtonStyles() {
  return {
    display: "flex",
    justifyContent: "center",
    boxShadow: 3,
    borderRadius: 2,
    width: "8rem",
    height: "3rem",
    gridColumnStart: 3,
  };
}

export function UserInformation<T>(props: IUserInformation<T>) {
  const { addEntry = true, columns, rows, properties, onClick, title } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {addEntry && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <DataIcon
            text={{
              children: `${title}`,
              variant: "h6",
              sx: { color: PRIMARY_COLOR, fontWeight: "bold" },
            }}
          />
          <Button
            type='addNote'
            onClick={onClick}
            data-testid='add-entry-button'
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              width: "8rem",
              height: "3rem",
            }}
          >
            + Add Note
          </Button>
        </Box>
      )}

      <Table
        data-testid='user-information-table'
        columns={columns}
        rowData={rows}
        displayCheckbox={properties.displayCheckbox}
        hidePagination={properties.hidePagination}
        isCustomToolBar={properties.customToolBar}
        hideFooter={properties.hideFooter}
        sxTableContainer={{ maxWidth: "100%" }}
        initialState={{}}
      />
    </Box>
  );
}
