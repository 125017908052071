import { DynamicModel } from "src/constants/types";
import { IReview } from "src/v2/models";
import {
  fetchAllPeopleData,
  getCustomFieldById,
  getPersonInformation,
} from "src/v2/services/peopleData.service";
import {
  createReview,
  getNextReviewDateByPersonId,
  getReviewsByPersonId,
  getReviewsDashboard,
  updateReview,
} from "src/v2/services/reviewData.service";

export interface INotes {
  [key: string]: string;
}

export async function getNotesByID(personId: string) {
  const notes: INotes = await getCustomFieldById(personId);
  return notes;
}

export async function fetchPersonData(personId: string) {
  const data = await getPersonInformation(personId);
  const { customFields, ...rest } = data;
  const contractType = customFields?.contractType
    ? customFields.contractType.name
    : null;
  const office = customFields?.office ? customFields.office.name : null;
  return { ...rest, ...customFields, contractType, office };
}

export async function listAllPeople(): Promise<DynamicModel[]> {
  const response = await fetchAllPeopleData();
  const people: any = response.map((item: any) => {
    const { name, customFields } = item.values;
    return {
      name,
      lastName: customFields.lastName,
      ioetId: customFields.ioetId,
    };
  });
  return people;
}

export async function listAllReviewsByPersonId(
  personId: string
): Promise<IReview[]> {
  const response = await getReviewsByPersonId(personId);
  const people: IReview[] = response.map((review: any) => {
    return {
      id: review.id,
      currentSalary: review.currentSalary,
      proposedSalary: review.proposedSalary,
      currentJobTitle: review.currentJob,
      proposedJobTitle: review.proposedJob,
      reviewDate: review.reviewDate,
      status: review.status,
      result: review.result,
      comments: review.comments?.comment,
    } as IReview;
  });
  return people;
}

export async function mutateReview(
  data: IReview,
  personId: string,
  newReview: boolean
) {
  const response = newReview
    ? await createReview(data, personId)
    : await updateReview(data, personId);

  return response;
}

export async function fetchNextReviewDate(personId: string) {
  const response = await getNextReviewDateByPersonId(personId);
  return response;
}

export async function listAllReviewsPerDateDashboard(date: string) {
  const response = await getReviewsDashboard(date);
  return response;
}

export async function getAllPeopleData(): Promise<DynamicModel[] | null>{
  const response = await fetchAllPeopleData();
  return response;
}
