import { createContext, useMemo, useState } from "react";
import useHasMounted from "../hooks/useHasMounted";
import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { DynamicModel } from "../models/dynamicModel.model";
import {
  DynamicViewTagComponent,
  IDynamicViewTagProps,
  ViewTagComponentMapping,
} from "../models/dynamicViewTag.model";
import { DataDefinitionSchemaFor } from "../services/dataDefinitionSchema.service";
import { fetchAllTimeNotifications } from "../services/peopleData.service";
import { fetchViewTagComponentMappingFor } from "../services/viewTagComponentMapping.service";

interface DynamicViewModelState {
  TimeNotificationData: DynamicModel | null;
  DynamicViewTagsComponents: DynamicViewTagComponent[];
  ViewTagComponentMapping: ViewTagComponentMapping | null;
  DataDefinitionSchema: IDataDefinitionSchema | null;
}

interface DynamicViewModelActions {
  initialize: (page_id: string) => Promise<void>;
  renderDynamicModelField: (
    columnField: string
  ) => null | ((value: any) => JSX.Element);
}

interface DynamicViewTagContextValue
  extends DynamicViewModelState,
    DynamicViewModelActions {}

interface IDynamicViewTagsProviderProps {
  children: React.ReactNode;
}

export const DynamicViewTagContext = createContext<DynamicViewTagContextValue>({
  TimeNotificationData: null,
  DynamicViewTagsComponents: [],
  ViewTagComponentMapping: null,
  DataDefinitionSchema: null,
  initialize: async (): Promise<void> => {},
  renderDynamicModelField: () => null,
});

function DynamicViewTagContextProvider({
  children,
}: IDynamicViewTagsProviderProps) {
  const hasMounted = useHasMounted();
  const [timeNotificationData, setTimeNotificationData] =
    useState<DynamicModel | null>(null);
  const [dataDefinition, setDataDefinition] =
    useState<IDataDefinitionSchema | null>(null);
  const [viewTagComponentMapping, setViewTagComponentMapping] =
    useState<ViewTagComponentMapping | null>(null);

  const initialize = async (page_id: string) => {
    try {
      const getViewTagComponentMapping =
        fetchViewTagComponentMappingFor(page_id);
      const dataDefinitionSchema = DataDefinitionSchemaFor;
      const getTimeNotifications = await fetchAllTimeNotifications();
      setTimeNotificationData(getTimeNotifications);
      setDataDefinition(dataDefinitionSchema);
      setViewTagComponentMapping(getViewTagComponentMapping);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert(`Error fetching  information: ${(error as Error).message}`);
    }
  };

  const renderDynamicModel = useMemo(() => {
    return (columnField: string) => {
      if (!dataDefinition || !viewTagComponentMapping) return null;
      const schemaFields = dataDefinition.fields;
      const { label, view_tag: viewTag, frozen } = schemaFields[columnField];
      const SelectedComponent =
        viewTagComponentMapping[viewTag as keyof ViewTagComponentMapping];
      return (value: JSX.Element) =>
        SelectedComponent && (
          <SelectedComponent
            {...({ value, frozen, label } as IDynamicViewTagProps)}
          />
        );
    };
  }, [dataDefinition, viewTagComponentMapping]);

  const contextValue: DynamicViewTagContextValue = useMemo(
    () => ({
      TimeNotificationData: timeNotificationData,
      DynamicViewTagsComponents: [],
      ViewTagComponentMapping: viewTagComponentMapping,
      DataDefinitionSchema: dataDefinition,
      initialize,
      renderDynamicModelField: renderDynamicModel,
    }),
    [timeNotificationData, viewTagComponentMapping, dataDefinition]
  );

  if (!hasMounted) return null;

  return (
    <DynamicViewTagContext.Provider value={contextValue}>
      {" "}
      {children}{" "}
    </DynamicViewTagContext.Provider>
  );
}

export default DynamicViewTagContextProvider;
