import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, LoadingButton, Typography } from "src/v2/components";
import { ConfirmDialog } from "src/v2/components/molecules/ConfirmDialog";
import {
  CLOSE_DIALOG_ICON_COLOR,
  DIALOG_BACKGROUND_COLOR,
  PRIMARY_COLOR,
} from "src/v2/constants/colors";
import { IDialogProps } from "./types";

function DialogModal({
  open,
  isSaving,
  handleCloseAndSave,
  title,
  form,
  reset,
  handleExit,
}: IDialogProps) {
  const [openCancelButton, setOpenCancelButton] = useState(false);

  const handleCancel = () => {
    setOpenCancelButton(true);
  };

  const handleLeave = () => {
    handleExit?.();
    reset?.();
    setOpenCancelButton(false);
  };

  const handleStay = () => {
    setOpenCancelButton(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (open) {
        e.preventDefault();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [open]);

  return (
    <Dialog
      scroll='body'
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='lg'
    >
      <div
        style={{
          backgroundColor: PRIMARY_COLOR,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "white" }}>
          <Typography variant='h6'>{title}</Typography>
        </DialogTitle>
        {handleCloseAndSave && (
          <IconButton
            aria-label='close'
            onClick={handleCancel}
            disabled={isSaving}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              background: CLOSE_DIALOG_ICON_COLOR,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </div>
      {form}
      {handleCloseAndSave && (
        <Stack
          direction='row'
          spacing={2}
          justifyContent='center'
          alignItems='center'
          sx={{
            background: DIALOG_BACKGROUND_COLOR,
            paddingBottom: "1rem",
            height: "150px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            padding: "3%",
          }}
        >
          <Button
            sx={{
              "borderRadius": 1,
              "width": "5rem",
              "height": "3rem",
              "fontSize": "0.85rem",
              "backgroundColor": "#E0E0E0",
              "color": "black",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
            variant='contained'
            onClick={handleCancel}
            disabled={isSaving}
            text='Cancel'
          />

          <LoadingButton
            sx={{
              borderRadius: 1,
              width: "5rem",
              height: "3rem",
              fontSize: "0.85rem",
              backgroundColor: PRIMARY_COLOR,
            }}
            variant='contained'
            loading={isSaving ?? false}
            onClick={handleCloseAndSave}
            text='Save'
          />
        </Stack>
      )}
      <ConfirmDialog
        confirmType={false}
        dialogTitle='Are you sure you want to leave without saving?'
        openCancelButton={openCancelButton}
        handleStay={handleStay}
        handleLeave={handleLeave}
      />
    </Dialog>
  );
}

export default DialogModal;
