import dayjs from "dayjs";
import { sanitizeArray } from "src/utils/parsers";
import { generateIncrementingNumbers } from "../../data/utils/generators";
import { IDataDefinitionSchema } from "../models/dataDefinition.model";
import { DynamicModel } from "../models/dynamicModel.model";
import { ViewTag } from "../models/dynamicViewTag.model";

export const dateToColumnLabelPresenter = (value: Date): string => {
  const getDateValues = value ? dayjs(value).format("DD-MM-YYYY") : undefined;

  return getDateValues!;
};

export const transformColumnLabel = (column_label: string) => {
  const formatedColumn = column_label.replace(/([a-z])([A-Z])/g, "$1 $2");
  const formatedColumnUppercase = formatedColumn.toUpperCase();
  return formatedColumnUppercase;
};

const getDynamicValue = (viewTag: ViewTag, value: any, label: string) => {
  let formatedValue = value ?? null;
  if (viewTag === ViewTag.date) {
    formatedValue = value ? new Date(`${value}T00:00:00`) : undefined;
  }
  return [label, formatedValue];
};

export const transformDataToTableData = (
  data: any[],
  dataDefinition: IDataDefinitionSchema
) => {
  const numberGenerator = generateIncrementingNumbers();

  const tableData = sanitizeArray(data).map((dynamicModel: DynamicModel) => {
    const { customFields, ...rest } = dynamicModel.values;
    const contractType = customFields?.contractType
      ? customFields.contractType.name
      : null;
    const office = customFields?.office ? customFields.office.name : null;

    const item: any = { ...rest, ...customFields, contractType, office };
    const transformedItem: any = Object.fromEntries(
      Object.entries(dataDefinition.fields)
        .filter(([field]) => field in item)
        .map(([field, { label, view_tag }]) =>
          getDynamicValue(view_tag, item[field], label)
        )
    );
    transformedItem.id = numberGenerator.next().value;
    return transformedItem;
  });
  return tableData;
};
