import { TextField as MuiTextField, Popover, Typography } from "@mui/material";
import React from "react";
import Icon from "src/v2/components/atoms/Icon/Icon";
import { IoetIcon, IoetIcons } from "src/v2/icons";
import { ITextFieldProps } from "./types";

const TextField: React.FC<ITextFieldProps> = ({
  id,
  label,
  type,
  hug = "",
  variant = "standard",
  value,
  onChange,
  sx,
  size,
  fullWidth = true,
  multiline = false,
  maxRows = 3,
  minRows = 1,
  read = true,
  disabled = false,
  className = "",
  classes = undefined,
  helperText,
  popoverText,
  hiddenLabel,
  name,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <>
      <MuiTextField
        error={Boolean(helperText)}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        label={label}
        type={type}
        value={value}
        onChange={onChange}
        variant={variant}
        sx={sx}
        size={size}
        name={name}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        helperText={helperText}
        hiddenLabel={hiddenLabel}
        classes={classes}
        InputProps={{
          startAdornment:
            hug in IoetIcons ? <Icon icon={hug as IoetIcon} /> : "",
          readOnly: read,
          className,
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      {popoverText && (
        <Popover
          id='mouse-over-popover'
          sx={{
            pointerEvents: "none",
          }}
          open={isPopoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{popoverText}</Typography>
        </Popover>
      )}
    </>
  );
};

export default TextField;
